import React, { useEffect, useState, useCallback } from "react";
import { SimpleDataTable } from "../../components/globals/DataTableXS";
import { formConfig } from "../../components/globals/useFormConfig";
import { UseAppConfig } from '../../system/config';
import { sendPostData } from '../../system/getData';
import { Box, Typography } from "@mui/material";
import CustomSwitch from "../../components/globals/CustomSwitch";
import { UploadProfile } from "../../components/globals/UploadImages";

const UserDetails = ({ dataConfig, refreshTable }) => {
    const { config } = UseAppConfig();
    const initValues = dataConfig?.initValues;
    const [status, setStatus] = React.useState(initValues?.status ?? false);

    if (!initValues?.id) return null;

    console.log("ID de usuario:", initValues);

    const handleSwitchChange = async (id, filtro, nuevoStatus) => {
        try {
            setStatus(nuevoStatus);

            const payload = { id, nuevoStatus };
            await sendPostData(`${config.API_BASE_URL}/actualizarStatus/${filtro}`, payload);
            console.log(`Estado actualizado correctamente: ID=${id}, Filtro=${filtro}, NuevoStatus=${nuevoStatus}`);

            if (refreshTable) {
                await refreshTable();
            }
        } catch (error) {
            console.error("Error al actualizar el estado:", error);
        }
    };

    return (
        <>
            <Box
                sx={{
                    width: "100%",
                    height: "70px",
                    backgroundColor: "#F2F2F2",
                    borderTopLeftRadius: 2,
                    borderTopRightRadius: 2,
                    position: "relative",
                    zIndex: 1,
                }}
            />
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    position: "relative",
                    zIndex: 2,
                    marginTop: "-60px",
                    padding: "16px",
                    gap: "14px",
                }}
            >
                <UploadProfile
                        id={initValues.id} 
                        perfilImagen={initValues?.perfilImagen}  
                        setPerfilImagen={(newImage) => {
                            initValues.perfilImagen = newImage;
                        }}
                        nombre={initValues?.nombre}
                        apellidos={initValues?.apellidos}
                        size={85}
                        inputId = {`upload-profile-${initValues.id}`}
                    />
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                    }}
                >
                    <Typography
                        variant="h3"
                        sx={{
                            fontWeight: "bold",
                            marginBottom: "10px",
                        }}
                    >
                        {`${initValues?.nombre} ${initValues?.apellidos}`}
                    </Typography>
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "8px",
                        }}
                    >
                        <img
                            alt="agregar-icon"
                            src={`${process.env.PUBLIC_URL}/assets/icons/navigation/date-picker.svg`}
                            width="20px"
                            height="20px"
                            style={{
                                cursor: "pointer",
                                filter: "grayscale(100%) brightness(10)",
                                opacity: 0.7,
                            }}
                        />
                        <Typography variant="body2" color="textSecondary">
                            {"Miembro desde:"}:{" "}
                            {initValues?.fechaCreacion
                                ? new Date(initValues.fechaCreacion).toLocaleDateString("es-ES", {
                                    day: "2-digit",
                                    month: "short",
                                    year: "numeric",
                                })
                                : "No disponible"}
                        </Typography>
                    </Box>
                </Box>
                <Box
                    sx={{
                        position: "absolute",
                        top: "70px",
                        right: "10px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: "#F2F2F2",
                        borderRadius: "15px",
                        padding: "1px",
                    }}
                >
                    <CustomSwitch
                        id={initValues.id}
                        filtro="usuarios"
                        checked={status}
                        onChange={handleSwitchChange}
                    />
                    <Typography
                        variant="body2"
                        color="textSecondary"
                        sx={{
                            marginRight: "10px",
                            marginTop: "3px",
                            marginLeft: "-5px",
                            fontSize: "14px",
                            fontWeight: "400",
                        }}
                    >
                        {status ? "Activo" : "Inactivo"}
                    </Typography>
                </Box>

                <Box
                    sx={{
                        position: "relative",
                        width: "calc(100% + 32px)",
                        marginLeft: "-16px",
                        marginTop: "5px",
                        borderBottom: "2px solid #ddd",
                    }}
                />
            </Box>

        </>
    );
};

const Users = () => {
    const { config } = UseAppConfig();
    const [configData, setConfigData] = useState(null);
    const [columns, setColumns] = useState([]);

    const generateColumns = useCallback(async (tableHeaders) => {
        const updatedColumns = tableHeaders
            .filter((header) => {
                const userData = JSON.parse(sessionStorage.getItem("userData"));
                const tipoUsuID = userData?.tipoUsuID;
                if (header.field === "organizacionNombre") {
                    return tipoUsuID === 1;
                }
                return true;
            })
            .map((header) => {
                if (header.field === "usuarioDetalle") {
                    return {
                        ...header,
                        renderCell: (params) => {
                            const { nombre = "", apellidos = "", perfilImagen, id } = params.row;

                            const getInitials = (nombre, apellidos) => {
                                const firstLetter = nombre?.charAt(0).toUpperCase() || "";
                                const lastLetter = apellidos?.charAt(0).toUpperCase() || "";
                                return `${firstLetter}${lastLetter}`;
                            };

                            const colors = ["#41a557", "#ff7043", "#42a5f5", "#ab47bc", "#ef5350", "#ffa726"];
                            const circleColor = colors[id % colors.length];
                            return (
                                <Box display="flex" alignItems="center" gap="8px">
                                    {perfilImagen ? (
                                        <img
                                            src={`${config.API_BASE_URL}/images/usuarios/${perfilImagen}`}
                                            alt={`${nombre} ${apellidos}`}
                                            style={{
                                                width: 30,
                                                height: 30,
                                                borderRadius: "50%",
                                                objectFit: "cover",
                                            }}
                                        />
                                    ) : (
                                        <Box
                                            sx={{
                                                width: 30,
                                                height: 30,
                                                borderRadius: "50%",
                                                backgroundColor: circleColor,
                                                color: "#fff",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                fontSize: "12px",
                                                fontWeight: "bold",
                                            }}
                                        >
                                            {getInitials(nombre, apellidos)}
                                        </Box>
                                    )}
                                    <span>{`${nombre} ${apellidos}`}</span>
                                </Box>
                            );
                        },
                    };
                }

                if (header.field === "tipoUsuID") {
                    return {
                        ...header,
                        renderCell: (params) => {
                            const roleMapping = {
                                1: "Admin",
                                2: "Gerente",
                                3: "Colaborador",
                            };
                            return <span>{roleMapping[params.value] || "Sin Rol"}</span>;
                        },
                    };
                }
                return header;
            });

        setColumns(updatedColumns);
    }, []);


    const fetchFormConfig = useCallback(
        async (type = "initialUser", data = null) => {
            try {
                const rawConfigData = await formConfig(type, data);

                const endpoint = rawConfigData.dataConfig?.getData || rawConfigData.endpoint;

                if (!endpoint) {
                    throw new Error(
                        `No se encontró un endpoint válido ni 'getData' en los datos de configuración para el tipo: ${type}`
                    );
                }

                const fullEndpoint = `${config.API_BASE_URL}${endpoint}`;

                const fullConfigData = {
                    ...rawConfigData,
                    dataConfig: {
                        ...rawConfigData.dataConfig,
                        endpoint: fullEndpoint,
                        validateSchemas: rawConfigData.dataConfig?.validateSchemas,
                    },
                };
                console.log("Configuración de validación recibida en fetchFormConfig:", fullConfigData.dataConfig?.validateSchemas);
                if (type === "initialUser") {
                    await generateColumns(fullConfigData.dataConfig.tableHeader || []);
                    setConfigData(fullConfigData);
                }

                return fullConfigData;
            } catch (error) {
                console.error("Error obteniendo formConfig:", error);
                throw error;
            }
        },
        [config, generateColumns]
    );

    useEffect(() => {
        fetchFormConfig();
    }, [fetchFormConfig]);

    return (
        <SimpleDataTable
            formConfig={{
                ...configData,
                dataConfig: {
                    ...configData?.dataConfig,
                    tableHeader: columns,
                    validateSchemas: configData?.dataConfig?.validateSchemas,
                },
            }}
            fetchFormConfig={fetchFormConfig}
            detailsComponent={UserDetails}
        />
    );
};

export default Users;