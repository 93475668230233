// profileFields.js
export const profileFields = {
    userFields: [
        {
            name: "NombreUsuario",
            label: "Nombre",
            gridColumn: "span 1",
            formType: "text",
        },
        {
            name: "ApellidosUsuario",
            label: "Apellido(s)",
            gridColumn: "span 1",
            formType: "text",
        },
        {
            name: "NombreOrganizacion",
            label: "Organización",
            gridColumn: "span 2",
            formType: "text",
        },
        {
            name: "CorreoUsuario",
            label: "Correo electrónico",
            gridColumn: "span 1",
            formType: "email",
        },
        {
            name: "TelefonoUsuario",
            label: "Teléfono",
            gridColumn: "span 1",
            formType: "tel",
        },
    ],
    organizationFields: [
        {
            name: "NombreOrganizacion",
            label: "Nombre de la Organización",
            gridColumn: "span 2",
            formType: "text",
        },
        {
            name: "GiroOrganizacion",
            label: "Giro",
            gridColumn: "span 1",
            formType: "select",
            catalog: "giros",
        },
        {
            name: "CorreoOrganizacion",
            label: "Correo de la Organización",
            gridColumn: "span 1",
            formType: "email",
        },
        {
            name: "TelefonoOrganizacion",
            label: "Teléfono (opcional)",
            gridColumn: "span 1",
            formType: "tel",
        },
        {
            name: "FactorUtilidadOrganizacion",
            label: "Factor de Utilidad",
            gridColumn: "span 1",
            formType: "number",
        },
    ],

    passwordFields: [
        {
            name: "currentPassword",
            label: "Contraseña actual",
            gridColumn: "span 1",
            formType: "password",
        },
        {
            name: "newPassword",
            label: "Nueva contraseña",
            gridColumn: "span 1",
            formType: "password",
        },
        {
            name: "confirmPassword",
            label: "Confirmar contraseña",
            gridColumn: "span 1",
            formType: "password",
        },
    ]
    
};
