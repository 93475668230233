export const categoriesFields = [
    {
        name: "nombre",
        label: "Categoría",
        gridColumn: "span 2", 
        placeholder: "Ingresa el nombre de categoría",
    },
    {
        name: "descripcion",
        label: "",
        gridColumn: "span 2", 
        formType: "textarea", 
    },
    {
        name: "factorUtilidad",
        label: "Factor de Utilidad",
        gridColumn: "span 1", 
        formType: "number",
        step: "0.01", 
        min: "0", 
    },
];
