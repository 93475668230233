import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import { MenuItem, Box, TextField, Container, Grid, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, useTheme, CircularProgress } from '@mui/material';

import { Formik } from "formik";
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { sendPostData } from '../../system/getData';
import useMediaQuery from "@mui/material/useMediaQuery";


import { UseAppConfig } from '../../system/config';

export const generarFormularioSimpleXS = ({ fields, initialValues, handleSubmit }) => {
    return (
        <form onSubmit={handleSubmit}>
            <Grid container spacing={2} padding={2}>
                {/* Generar campos dinámicamente */}
                {fields.map((field) => (
                    <Grid
                        item
                        xs={12}
                        sm={field.gridColumn === "span 2" ? 12 : 6}
                        key={field.name}
                    >
                        {field.formType === "select" ? (
                            <TextField
                                select
                                fullWidth
                                label={field.label}
                                name={field.name}
                                defaultValue={initialValues[field.name]}
                                variant="outlined"
                                size="small"
                            >
                                {field.catalog && field.catalog.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        ) : field.name === "telefono" ? (
                            <Box display="flex" alignItems="center" gap={1}>
                                <TextField
                                    select
                                    variant="outlined"
                                    size="small"
                                    defaultValue={"+52"} // País por defecto
                                    sx={{ width: "20%" }}
                                >
                                    <MenuItem value={"+52"}>+52</MenuItem>
                                    <MenuItem value={"+1"}>+1</MenuItem>
                                    {/* Agregar más países */}
                                </TextField>
                                <TextField
                                    fullWidth
                                    label={field.label}
                                    name={field.name}
                                    type={field.formType}
                                    defaultValue={initialValues[field.name]}
                                    variant="outlined"
                                    size="small"
                                    sx={{ flex: 1 }}
                                />
                            </Box>
                        ) : (
                            <TextField
                                fullWidth
                                label={field.label}
                                name={field.name}
                                type={field.formType}
                                defaultValue={initialValues[field.name]}
                                variant="outlined"
                                size="small"
                            />
                        )}
                    </Grid>
                ))}

                {/* Botones de acción */}
                <Grid item xs={12} display="flex" justifyContent="space-between" gap={2}>
                    <Button
                        variant="outlined"
                        color="default"
                        type="button"
                        sx={{
                            textTransform: "none",
                            padding: "8px 16px",
                            fontSize: "14px",
                            borderColor: "#6c757d",
                            color: "#6c757d",
                        }}
                    >
                        Cancelar
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        sx={{
                            textTransform: "none",
                            padding: "8px 16px",
                            fontSize: "14px",
                        }}
                    >
                        Guardar
                    </Button>
                </Grid>
            </Grid>
        </form>
    );
};

export const GeneraFormularioSimple = ({ data, initValues, validateSchemas }) => {
	const navigate = useNavigate();
	const { enqueueSnackbar } = useSnackbar();
	const isNonMobile = useMediaQuery("(min-width:600px)");

	const { config, configData } = UseAppConfig();

	const formatCurrency = (value) => {
		return new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(value);
	};

	const handleFormSubmit = async (values) => {
		console.log('Detalles del Registro: ' + JSON.stringify(values));
		console.log('Enviando datos a la API');
		try {
			const result = await sendPostData(`${config.API_BASE_URL}/${data.sendData}`, values);
			console.log('Formulario actualizado exitosamente:', result);
			enqueueSnackbar(data.msgSuccess, { variant: 'success' });
			navigate(`/${data.module}`);
		} catch (error) {
			console.error('Error al enviar datos:', error);
			enqueueSnackbar(data.msgError, { variant: 'error' });
		}
	};

	const handleBack = () => {
		navigate(-1);
	};

	return (
		<Box>
			
		</Box>
	);
};

export const GeneraFormularioAutenticacion = ({data, initValues, validateSchemas, handleFormSubmit }) => {
	const [rememberUser, setRememberUser] = useState(false);
	const [formValues, setFormValues] = useState(initValues);
	const [passwordVisible, setPasswordVisible] = useState(false); 

	useEffect(() => {
		const savedValues = JSON.parse(localStorage.getItem("userCredentials"));
		if (savedValues) {
			setFormValues((prev) => ({ ...prev, ...savedValues }));
			setRememberUser(true);
		}
	}, []);

	const handleCheckboxChange = (event) => {
		const isChecked = event.target.checked;
		setRememberUser(isChecked);
		if (!isChecked) {
			localStorage.removeItem("userCredentials");
		}
	};

	const handleFormSubmitWithRemember = async (values, actions) => {
		try {
			if (rememberUser) {
				localStorage.setItem("userCredentials", JSON.stringify(values));
			}
			await handleFormSubmit(values, actions);
		} catch (error) {
			console.error("Error al autenticar:", error);
		} finally {
			actions.setSubmitting(false);
		}
	};

	const togglePasswordVisibility = () => {
		setPasswordVisible((prev) => !prev); 
	};

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				justifyContent: "center",
				minHeight: "100vh",
				position: "relative",
			}}
		>
			<Box
				sx={{
					display: "flex",
					justifyContent: "space-between",
					width: "900px",
				}}
			>
				<Box
					sx={{
						maxWidth: "500px",
						alignItems: "center",
					}}
				>
					<img
						src={`${process.env.PUBLIC_URL}/assets/icons/login/login-banner.svg`}
						alt="PU Logo"
						style={{
							height: "650px",
						}}
					/>
				</Box>
				<Box
					sx={{
						width: "35%",
						maxWidth: "450px",
						paddingLeft: "30px",
						borderRadius: "8px",
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
					}}
				>
					<Typography
						variant="h3"
						gutterBottom
						sx={{
							fontWeight: "bold",
							textAlign: "left",
							marginBottom: "20px",
						}}
					>
						{data.title}
					</Typography>
					<Formik
						initialValues={formValues}
						enableReinitialize
						validationSchema={validateSchemas}
						onSubmit={handleFormSubmitWithRemember}
					>
						{({
							values,
							touched,
							errors,
							handleChange,
							handleBlur,
							handleSubmit,
							isSubmitting,
						}) => (
							<form onSubmit={handleSubmit}>
								{data.fields.map((field) => (
									<Box key={field.name} sx={{ marginBottom: "20px" }}>
										<Typography
											variant="body1"
											sx={{
												textAlign: "left",
												marginBottom: "5px", 
											}}
										>
											{field.label}
										</Typography>
										<TextField
											fullWidth
											variant="standard"
											type={
												field.type === "password" && passwordVisible
													? "text"
													: field.type
											}
											name={field.name}
											value={values[field.name]}
											onChange={handleChange}
											onBlur={handleBlur}
											error={
												touched[field.name] &&
												Boolean(errors[field.name])
											}
											helperText={
												touched[field.name] && errors[field.name]
											}
											placeholder={
												field.name === "correo"
													? "Ingresa un correo electrónico"
													: "Ingresa tu contraseña"
											}
											InputProps={{
												endAdornment: field.type === "password" && (
													<Box
														onClick={togglePasswordVisibility}
														sx={{
															cursor: "pointer", 
															display: "flex", 
															alignItems: "center",
														}}
													>
														<img
															src={`${process.env.PUBLIC_URL}/assets/icons/login/${passwordVisible
																	? "eye-active.svg"
																	: "eye-inactive.svg"
																}`}
															alt="Toggle Password Visibility"
															style={{
																width: "20px", 
																height: "20px",
															}}
														/>
													</Box>
												),
											}}
											sx={{
												"& .MuiInputBase-root": {
													marginTop: "3px",
													borderRadius: "8px",
													border: "1px solid #ccc",
													padding: "2px 8px",
												},
												"& input": {
													Webkittextsecurity:
														field.type === "password" &&
															!passwordVisible
															? "disc"
															: "none", 
													appearance: "none", 
													"&::-ms-reveal, &::-ms-clear": {
														display: "none", 
													},
													"&::-webkit-clear-button, &::-webkit-inner-spin-button, &::-webkit-textfield-decoration-container": {
														display: "none", 
													},
												},
												"& .MuiInput-underline:before, & .MuiInput-underline:after": {
													display: "none", 
												},
											}}
										/>
									</Box>
								))}

								{data.showPasswordLink && (
									<Box
										sx={{
											mt: 1,
											display: "flex",
											alignItems: "center",
											justifyContent: "space-between",
										}}
									>
										<Box sx={{ display: "flex", alignItems: "center" }}>
											<input
												type="checkbox"
												id="rememberUser"
												style={{ marginRight: "5px" }}
												checked={rememberUser}
												onChange={handleCheckboxChange}
											/>
											<label
												htmlFor="rememberUser"
												style={{ fontSize: "12px", color: "#000" }}
											>
												Recordar usuario
											</label>
										</Box>

										<Box>
											<Typography variant="body2" sx={{ textAlign: "right" }}>
												<Link
													to="/passrecover"
													style={{
														color: "#3f51b5",
														textDecoration: "underline",
														fontSize: "12px",
													}}
												>
													Olvidé mi contraseña
												</Link>
											</Typography>
										</Box>
									</Box>
								)}

								<Button
									type="submit"
									variant="contained"
									color="primary"
									fullWidth
									sx={{
										mt: 1,
										height: "36px",
										backgroundColor: "#1D74D3",
										borderRadius: "8px",
										marginTop: "20px",
									}}
									disabled={isSubmitting}
								>
									{isSubmitting ? <CircularProgress size={20} /> : ""}
									{data.titleBtn}
								</Button>
							</form>
						)}
					</Formik>
				</Box>
			</Box>
			<Box
				sx={{
					position: "absolute",
					bottom: 20,
					right: 30,
					textAlign: "right",
					backgroundColor: "transparent",
				}}
			>
				<Typography variant="body2" sx={{ color: "#000" }}>
					2024 @ Exagono Software
				</Typography>
			</Box>
		</Box>
	);
};

export const GeneraFormularioCorreo = ({ data, initValues, validateSchemas, handleFormSubmit }) => {
	const navigate = useNavigate();
	const [isFormVisible, setIsFormVisible] = useState(true);
	const [submittedEmail, setSubmittedEmail] = useState("");

	return (
		<Box
			sx={{
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				minHeight: "100vh",
				position: "relative", 
			}}
		>
			<Box
				sx={{
					position: "absolute", 
					top: 20,
					left: 20, 
				}}
			>
				<img
					src={`${process.env.PUBLIC_URL}/assets/icons/navigation/PU-logo.svg`}
					alt="PU Logo"
					style={{
						height: "25px",
						pointerEvents: "none",
					}}
				/>
			</Box>

			<Box
				sx={{
					display: "flex",
					alignItems: "center",
					justifyContent: "space-between",
					width: "900px",
				}}
			>
				<Box sx={{ width: "35%" }}>
					{isFormVisible ? (
						<>
							<Typography
								variant="h4"
								gutterBottom
								sx={{
									fontWeight: "bold",
									textAlign: "left",
									marginBottom: "20px",
								}}
							>
								{data.title}
							</Typography>
							<Typography
								variant="body1"
								sx={{
									textAlign: "left",
									marginBottom: "20px",
								}}
							>
								{data.description}
							</Typography>
							<Formik
								initialValues={initValues}
								validationSchema={validateSchemas}
								onSubmit={(values, { setSubmitting }) => {
									handleFormSubmit(values);
									setSubmittedEmail(values.correo);
									setSubmitting(false);
									setIsFormVisible(false);
								}}
							>
								{({
									values,
									touched,
									errors,
									handleChange,
									handleBlur,
									handleSubmit,
									isSubmitting,
								}) => (
									<form onSubmit={handleSubmit}>
										<Box>
											<Typography
												variant="body1"
												sx={{
													fontWeight: "bold",
													textAlign: "left",
													
												}}
											>
												Correo electrónico
											</Typography>
											<TextField
												fullWidth
												variant="standard"
												type="email"
												name="correo"
												value={values.correo}
												onChange={handleChange}
												onBlur={handleBlur}
												error={touched.correo && Boolean(errors.correo)}
												helperText={touched.correo && errors.correo}
												placeholder="Ingresa tu correo electrónico"
												sx={{
													"& .MuiInputBase-root": {
														marginTop: "3px",
														borderRadius: "8px",
														border: "1px solid #ccc",
														height: "40px",
														padding: "2px 8px",
													},
													"& .MuiInput-underline:before, & .MuiInput-underline:after": {
														display: "none",
													},
												}}
											/>
										</Box>
										<Box
											sx={{
												display: "flex",
												justifyContent: "space-between",
												gap: "16px",
												marginTop: "30px",
											}}
										>
											<Button
												variant="contained"
												sx={{
													height: "40px",
													borderRadius: "8px",
													fontSize:"13px",
													width: "48%",
													backgroundColor: "#374151",
													textTransform: "none",
													color: "#fff",
													"&:hover": {
														backgroundColor: "#4b5563",
													},
												}}
												onClick={() => navigate("/login")}
											>
												Cancelar
											</Button>
											<Button
												type="submit"
												variant="contained"
												color="primary"
												sx={{
													height: "40px",
													borderRadius: "8px",
													textTransform: "none",
													fontSize:"13px",
													width: "280px",
													backgroundColor: "#1D74D3",
												}}
												disabled={isSubmitting}
											>
												{isSubmitting ? (
													<CircularProgress size={20} />
												) : (
													data.titleBtn
												)}
											</Button>
										</Box>
									</form>
								)}
							</Formik>
						</>
					) : (
						<>
							<Typography
								variant="h3"
								gutterBottom
								sx={{
									fontWeight: "bold",
									textAlign: "left",
									marginBottom: "20px",
								}}
							>
								Revisa tu e-mail
							</Typography>
							<Typography
								variant="body1"
								sx={{
									textAlign: "left",
									color: "#666",
									marginBottom: "10px",
								}}
							>
								Hemos enviado un correo electrónico con instrucciones para recuperar tu contraseña.
							</Typography>
							<Box
								sx={{
									display: "flex",
									alignItems: "center",
									gap: "8px",
									marginBottom: "30px",
								}}
							>
								<Typography
									variant="body1"
									sx={{
										color: "#1D74D3",
										cursor: "pointer",
										display: "flex",
										alignItems: "center",
									}}
								>
									{submittedEmail}
								</Typography>
								<img
									src={`${process.env.PUBLIC_URL}/assets/icons/login/editar-cl.svg`}
									alt="Editar correo"
									style={{
										height: "15px",
										cursor: "pointer",
									}}
									onClick={() => {
										setIsFormVisible(true);
										setSubmittedEmail("");
									}}
								/>
							</Box>
							<Button
								variant="contained"
								color="primary"
								sx={{
									width: "50%",
									height: "40px",
									borderRadius: "5px",
									backgroundColor: "#1D74D3",
								}}
								onClick={() =>
									navigate("/login", { state: { email: submittedEmail } })
								}
							>
								Aceptar
							</Button>
						</>
					)}
				</Box>
				<Box>
					<img
						src={`${process.env.PUBLIC_URL}/assets/icons/login/recovery-pss_img.svg`}
						alt="Banner"
						style={{
							height: "650px",
						}}
					/>
				</Box>
			</Box>
		</Box>
	);
};

export const GeneraFormularioCambioContrasena = ({data, initValues, validateSchemas, handleFormSubmit }) => {
	const navigate = useNavigate();
	const [isFormVisible, setIsFormVisible] = useState(true);
	const [validationStatus, setValidationStatus] = useState({
		hasUppercase: false,
		hasLowercase: false,
		hasNumber: false,
		hasSpecialChar: false,
	});
	const [passwordVisible, setPasswordVisible] = useState(false);

	const handlePasswordChange = (e) => {
		const value = e.target.value;
		setValidationStatus({
			hasUppercase: /[A-Z]/.test(value),
			hasLowercase: /[a-z]/.test(value),
			hasNumber: /\d/.test(value),
			hasSpecialChar: /[@$!%*?&]/.test(value),
		});
	};

	const ValidationIcon = ({ isActive }) => (
		<>
			{isActive ? (
				<img
					src={`${process.env.PUBLIC_URL}/assets/icons/login/check-ic_color.svg`}
					alt="Check Icon"
					style={{
						height: "16px",
						marginRight: "8px",
					}}
				/>
			) : (
				<span
					style={{
						display: "inline-block",
						marginRight: "8px",
						fontSize: "16px",
						color: "#000",
					}}
				>
					•
				</span>
			)}
		</>
	);

	return (
		<Box
			sx={{
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				minHeight: "100vh",
				backgroundColor: "#EDEDEE",
				padding: "20px",
			}}
		>
			<Box
				sx={{
					width: "100%",
					maxWidth: "350px",
					backgroundColor: "#fff",
					boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.3)",
					borderRadius: "5px",
					padding: "30px",
					textAlign: "center",
				}}
			>
				{isFormVisible ? (
					<>
						<Box sx={{ marginBottom: "20px" }}>
							<img
								src={`${process.env.PUBLIC_URL}/assets/icons/login/cambiar-pss-img.svg`}
								alt="Recover Password Icon"
								style={{ height: "70px", paddingBottom: "10px" }}
							/>
						</Box>
						<Typography
							variant="h4"
							gutterBottom
							sx={{
								fontWeight: "bold",
								textAlign: "left",
								marginBottom: "20px",
							}}
						>
							{data.title}
						</Typography>
						<Formik
							initialValues={initValues}
							validationSchema={validateSchemas}
							onSubmit={(values, { setSubmitting }) => {
								handleFormSubmit(values);
								setSubmitting(false);
								setIsFormVisible(false);
							}}
						>
							{({
								values,
								touched,
								errors,
								handleChange,
								handleBlur,
								handleSubmit,
								isSubmitting,
							}) => (
								<form onSubmit={handleSubmit}>
									<Box sx={{ position: "relative", marginBottom: "20px" }}>
										<Typography
											variant="body1"
											sx={{
												textAlign: "left",
												marginBottom: "5px",
											}}
										>
											{data.fields[0].label}
										</Typography>
										<TextField
											fullWidth
											variant="standard"
											type={passwordVisible ? "text" : "password"}
											name={data.fields[0].name}
											value={values[data.fields[0].name]}
											onChange={(e) => {
												handleChange(e);
												handlePasswordChange(e);
											}}
											onBlur={handleBlur}
											error={
												touched[data.fields[0].name] &&
												Boolean(errors[data.fields[0].name])
											}
											helperText={
												touched[data.fields[0].name] &&
												errors[data.fields[0].name]
											}
											sx={{
												"& .MuiInputBase-root": {
													marginTop: "3px",
													borderRadius: "8px",
													border: "1px solid #ccc",
													padding: "2px 8px",

												},
												"& input": {
													"-webkit-text-security": {
														appearance: "none"
													},
													"&::-ms-reveal, &::-ms-clear": {
														display: "none", 
													},
													"&::-webkit-clear-button, &::-webkit-inner-spin-button, &::-webkit-textfield-decoration-container": {
														display: "none", 
													},
												},
												"& .MuiInput-underline:before, & .MuiInput-underline:after": {
													display: "none",
												},
											}}
										/>
									</Box>
									<Box
										sx={{
											textAlign: "left",
											fontSize: "12px",
											marginBottom: "20px",
										}}
									>
										<Typography
											variant="body1"
											sx={{
												marginBottom: "10px",
											}}
										>
											La contraseña debe incluir al menos:
										</Typography>
										<ul
											style={{
												fontSize: "14px",
												paddingLeft: "20px",
												listStyle: "none",
											}}
										>
											<li
												style={{
													display: "flex",
													alignItems: "center",
													color: validationStatus.hasUppercase
														? "#10B981"
														: "#000",
												}}
											>
												<ValidationIcon
													isActive={validationStatus.hasUppercase}
												/>
												Una letra mayúscula
											</li>
											<li
												style={{
													display: "flex",
													alignItems: "center",
													color: validationStatus.hasLowercase
														? "#10B981"
														: "#000",
												}}
											>
												<ValidationIcon
													isActive={validationStatus.hasLowercase}
												/>
												Una minúscula
											</li>
											<li
												style={{
													display: "flex",
													alignItems: "center",
													color: validationStatus.hasNumber
														? "#10B981"
														: "#000",
												}}
											>
												<ValidationIcon
													isActive={validationStatus.hasNumber}
												/>
												Un número
											</li>
											<li
												style={{
													display: "flex",
													alignItems: "center",
													color: validationStatus.hasSpecialChar
														? "#10B981"
														: "#000",
												}}
											>
												<ValidationIcon
													isActive={validationStatus.hasSpecialChar}
												/>
												Un carácter especial
											</li>
										</ul>
									</Box>
									<Box sx={{ position: "relative", marginBottom: "20px" }}>
										<Typography
											variant="body1"
											sx={{
												textAlign: "left",
												marginBottom: "5px",
											}}
										>
											{data.fields[1].label}
										</Typography>
										<TextField
											fullWidth
											variant="standard"
											type={passwordVisible ? "text" : "password"}
											name={data.fields[1].name}
											value={values[data.fields[1].name]}
											onChange={(e) => {
												handleChange(e);
												handlePasswordChange(e);
											}}
											onBlur={handleBlur}
											error={
												touched[data.fields[1].name] &&
												Boolean(errors[data.fields[1].name])
											}
											helperText={
												touched[data.fields[1].name] &&
												errors[data.fields[1].name]
											}
											sx={{
												"& .MuiInputBase-root": {
													marginTop: "3px",
													borderRadius: "8px",
													border: "1px solid #ccc",
													padding: "2px 8px",

												},
												"& input": {
													"-webkit-text-security": {
														appearance: "none"
													},
													"&::-ms-reveal, &::-ms-clear": {
														display: "none", 
													},
													"&::-webkit-clear-button, &::-webkit-inner-spin-button, &::-webkit-textfield-decoration-container": {
														display: "none", 
													},
												},
												"& .MuiInput-underline:before, & .MuiInput-underline:after": {
													display: "none",
												},
											}}
										/>
									</Box>

									<Button
										type="submit"
										variant="contained"
										color="primary"
										sx={{
											height: "45px",
											borderRadius: "5px",
											width: "100%",
											backgroundColor: "#1D74D3",
											fontWeight: "bold",
											"&:hover": {
												backgroundColor: "#155A9C",
											},
										}}
										disabled={isSubmitting}
									>
										{isSubmitting ? (
											<CircularProgress size={20} />
										) : (
											data.titleBtn
										)}
									</Button>
								</form>
							)}
						</Formik>
					</>
				) : (
					<>
						<Box
							sx={{
								marginBottom: "20px",
								minHeight: "350px",
								display: "flex",
								flexDirection: "column",
								alignItems: "center",
								justifyContent: "center",
							}}
						>
							<Box
								sx={{
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
									marginBottom: "20px",
								}}
							>
								<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24">
									<path
										fill="#2E7D32"
										d="M12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12S18.627 0 12 0zm-2 17.414L5.586 13 7 11.586l3 3 7-7L19.414 9l-9 9z"
									/>
								</svg>
							</Box>
							<Typography
								variant="h3"
								gutterBottom
								sx={{
									fontWeight: "bold",
									textAlign: "center",
									marginBottom: "20px",
								}}
							>
								Contraseña actualizada
							</Typography>
							<Typography
								variant="body1"
								sx={{
									textAlign: "center",
									color: "#666",
									marginBottom: "20px",
								}}
							>
								¡Listo! Tu contraseña ha sido cambiada. Vuelve a iniciar sesión con tu nueva contraseña.
							</Typography>

							<Button
								variant="contained"
								color="primary"
								sx={{
									width: "60%",
									height: "45px",
									borderRadius: "5px",
									backgroundColor: "#1D74D3",
									fontWeight: "bold",
									"&:hover": {
										backgroundColor: "#155A9C",
									},
								}}
								onClick={() => navigate("/login")}
							>
								Aceptar
							</Button>
						</Box>
					</>
				)}
			</Box>
		</Box>
	);
};