import React, { createContext, useContext, useState, useEffect } from 'react';
import { UseAppConfig } from '../../system/config';

const LogoContext = createContext();
export const LogoProvider = ({ children }) => {
    const { config } = UseAppConfig();
    const [logos, setLogos] = useState(() => {
        const userData = JSON.parse(sessionStorage.getItem('userData')) || {};
        const organizationID = userData.organizacionID;
        const imageName = userData.organizacionPerfilImagen;

        if (organizationID && imageName) {
            return { [organizationID]: `${config.API_BASE_URL}/images/organizaciones/${imageName}` };
        }

        return {};
    });

    const setLogoUrlForOrganization = (organizationID, logoUrl) => {
        setLogos((prevLogos) => {
            const updatedLogos = { ...prevLogos, [organizationID]: logoUrl };

            const userData = JSON.parse(sessionStorage.getItem('userData')) || {};
            userData.organizacionPerfilImagen = logoUrl.split('/').pop(); 
            sessionStorage.setItem('userData', JSON.stringify(userData));

            return updatedLogos;
        });
    };

    const getLogoUrlForOrganization = (organizationID) => logos[organizationID] || '';

    return (
        <LogoContext.Provider value={{ getLogoUrlForOrganization, setLogoUrlForOrganization }}>
            {children}
        </LogoContext.Provider>
    );
};


export const useLogo = () => useContext(LogoContext);
