import { useState, useEffect } from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import { CssBaseline, ThemeProvider, Box } from "@mui/material";
import { ColorModeContext, useMode } from "./theme/theme";

import Header from "./components/includes/Header";
import Sidebar from "./components/includes/Sidebar";

import Dashboard from "./modules/dashboard";

import Usuarios from "./modules/users";
import Organizations from "./modules/organizations";
import Categories from "./modules/categories";
import Settings from "./components/includes/Settings"
import Articles from "./modules/articles";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";

import "bootstrap/dist/css/bootstrap.min.css";

import Login from "./components/includes/Login";
import PasswordRecover from "./components/includes/PasswordRecover";
import ChangePassword from "./components/includes/ChangePassword";

library.add(fas);

function App() {
    const [theme, colorMode] = useMode();
    const [selected, setSelected] = useState("dashboard");
    const [isCollapsed, setIsCollapsed] = useState(false);
    const location = useLocation();

    const [loading, setLoading] = useState(true);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [userData, setUserData] = useState(null); // Almacena todos los datos del usuario
    
    useEffect(() => {
        const storedUserData = sessionStorage.getItem("userData");
        if (storedUserData) {
            setUserData(JSON.parse(storedUserData));
            setIsLoggedIn(true);
        }
        setLoading(false);
    }, []);

    const handleLogin = (id, correo, tipoUsuID, organizacionID, nombre, apellidos, usuarioPerfilImagen, organizacionPerfilImagen) => {
        const newUserData = { id, correo, tipoUsuID, organizacionID, nombre, apellidos, usuarioPerfilImagen, organizacionPerfilImagen };
        setUserData(newUserData); // Guarda los datos del usuario en el estado
        sessionStorage.setItem("userData", JSON.stringify(newUserData)); // Guarda en sessionStorage también
        setIsLoggedIn(true); // Cambia el estado de inicio de sesión
    };

    if (loading) {
    }

    return (
        <ColorModeContext.Provider value={colorMode}>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <Box
                    sx={{
                        backgroundColor: "#F4F6F8",
                        minHeight: "100vh",
                    }}
                >
                    <Routes>
                        <Route path="/passrecover" element={<PasswordRecover />} />
                        <Route path="/passrecover/change" element={<ChangePassword />} />

                        {isLoggedIn ? (
                            <Route
                                path="/*"
                                element={
                                    <Box display="flex" width="100%">
                                        <Sidebar
                                            isCollapsed={isCollapsed}
                                            setIsCollapsed={setIsCollapsed}
                                            selected={selected} 
                                            setSelected={setSelected}
                                        />
                                        <Box
                                            sx={{
                                                marginLeft: isCollapsed ? "80px" : "280px",
                                                padding: "30px",
                                                width: "100%",
                                                transition: "margin-left 0.3s ease-in-out",
                                                marginTop: "30px",
                                            }}
                                        >
                                            <Header
                                                isCollapsed={isCollapsed}
                                                setIsLoggedIn={setIsLoggedIn}
                                                selected={selected} 
                                                setSelected={setSelected}
                                            />
                                            <Routes>
                                                <Route path="/" element={<Dashboard />} />
                                                <Route path="/users" element={<Usuarios />} />
                                                <Route path="/organizations" element={<Organizations />} />
                                                <Route path="/categories" element={<Categories/>} />
                                                <Route path="/articles" element={<Articles/>} />
                                                <Route path="/settings" element={<Settings/>} />
                                            </Routes>
                                        </Box>
                                    </Box>
                                }
                            />
                        ) : (
                            <Route
                                path="/*"
                                element={
                                    <Login
                                        onLogin={(id, correo, tipoUsuID, organizacionID, nombre, apellidos, usuarioPerfilImagen, organizacionPerfilImagen) =>
                                            handleLogin(id, correo,tipoUsuID, organizacionID, nombre, apellidos, usuarioPerfilImagen, organizacionPerfilImagen)
                                        }
                                    />
                                }
                            />
                        )}
                        <Route path="*" element={<Navigate to="/" />} />
                    </Routes>
                </Box>
            </ThemeProvider>
        </ColorModeContext.Provider>
    );
}

export default App;
