import { Box } from "@mui/material";

export const tableHeaders = [
    {
        field: "nombre",
        headerName: "Organización",
        headerAlign: "left",
        align: "left",
        width: 200, 
    },
    {
        field: "factorUtilidad",
        headerName: "Factor Utilidad",
        headerAlign: "left",
        align: "left",
        flex: 1, 
        minWidth: 130, 
    },
    {
        field: "correo",
        headerName: "Correo Electrónico",
        headerAlign: "left",
        align: "left",
        flex: 1, 
        minWidth: 220, 
    },
    {
        field: "giro",
        headerName: "Giro",
        headerAlign: "left",
        align: "left",
        width: 180, 
    },
    {
        field: "fechaCreacion",
        headerName: "Fecha de Creación",
        headerAlign: "left",
        align: "left",
        width: 200, 
    },
    {
        field: "status",
        headerName: "Activo",
        headerAlign: "center",
        align: "left",
        width: 80, 
        isSwitch: true,
    },
    {
        field: "acciones",
        headerName: "",
        headerAlign: "center",
        align: "center",
        width: 100, 
        renderCell: () => (
            <Box display="flex" gap="15px">
                <img
                    src={`${process.env.PUBLIC_URL}/assets/icons/navigation/eliminar.svg`}
                    alt="Eliminar"
                    width="25"
                    height="25"
                    style={{ cursor: "pointer" }}
                />
                <img
                    src={`${process.env.PUBLIC_URL}/assets/icons/navigation/editar.svg`}
                    alt="Editar"
                    width="25"
                    height="25"
                    style={{ cursor: "pointer" }}
                />
            </Box>
        ),
    },
];
