import React, { useEffect, useState } from "react";
import { Select, MenuItem, Checkbox, ListItemText, FormControl } from "@mui/material";
import { getDataArray } from "../../system/getData";
import { UseAppConfig } from "../../system/config";

const CustomArrowIcon = (props) => (
    <img
        {...props}
        src={`${process.env.PUBLIC_URL}/assets/icons/navigation/arrow-drop.svg`}
        style={{
            height: "18px",
            width: "18px",
            pointerEvents: "auto",
            cursor: "pointer",
            position: "relative",
            top: "2px",
        }}
        alt="custom icon"
    />
);

const ComboBoxFilled = ({ data, value, onChange, placeholder }) => {
    const [rows, setRows] = useState([]);
    const { config } = UseAppConfig();

     useEffect(() => {
        const fetchData = async () => {
            const userData = JSON.parse(sessionStorage.getItem("userData"));
        const tipoUsuID = userData?.tipoUsuID;
            try {
                const response = await getDataArray(`${config.API_BASE_URL}/catalogo/${data.catalog}`);
                if (Array.isArray(response)) {
                    const filteredResponse =
                        tipoUsuID === 2
                            ? response.filter((row) => row.nombre !== "Admin")
                            : response;

                    setRows(filteredResponse);
                } else {
                    console.warn("Respuesta inesperada, se esperaba un array:", response);
                    setRows([]);
                }
            } catch (error) {
                console.error("Error al cargar datos:", error);
                setRows([]);
            }
        };

        fetchData();
    }, [data.catalog, config.API_BASE_URL, value]);

    return (
        <FormControl
            fullWidth
            variant="standard"
            sx={{
                "& .MuiInputBase-root": {
                    borderRadius: "8px",
                    border: "1px solid #ccc",
                    padding: "8px 12px",
                    height: "48px",
                    backgroundColor: "#fff",
                    "&:hover": { borderColor: "#aaa" },
                },
                "& .MuiInput-underline:before": {
                    borderBottom: "none",
                },
                "& .MuiInput-underline:after": {
                    borderBottom: "none",
                },
                "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                    borderBottom: "none",
                },
            }}
        >
            <Select
    value={rows.some((row) => row.id === value) ? value : ""}
    onChange={onChange}
    displayEmpty
    IconComponent={CustomArrowIcon}
    MenuProps={{
        PaperProps: {
            style: {
                maxHeight: 200,
                overflowY: "auto",
            },
        },
    }}
    renderValue={(selected) => {
        return rows.find((row) => row.id === selected)?.nombre || (
            <span style={{ color: "#a7a7a7" }}>
                {placeholder}
            </span>
        );
    }}
>
    <MenuItem disabled value="">
        <span style={{ color: "#a7a7a7" }}>
            {placeholder}
        </span>
    </MenuItem>
    {rows.map((row) => (
        <MenuItem key={row.id} value={row.id}>
            {data.multiple === 1 && <Checkbox checked={value?.indexOf(row.id) > -1} />}
            <ListItemText primary={row.nombre} />
        </MenuItem>
    ))}
</Select>

        </FormControl>
    );
};

export default ComboBoxFilled;
