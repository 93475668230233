import React, { useState, useEffect } from "react";
import { Box, Button, Checkbox, FormControlLabel, Typography, Paper, Popper, ClickAwayListener, Grow, TextField } from "@mui/material";
import { getPostData, sendPostData } from "../../system/getData";
import { UseAppConfig } from "../../system/config";

export const FilterComponent = ({ catalogo, onApplyFilter, id, filtro, filterKey, filterText }) => {
    const [filters, setFilters] = useState([]);
    const [selectedFilters, setSelectedFilters] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState([]);
    const [statusFilters, setStatusFilters] = useState([
        { id: "status-1", nombre: "Activo" },
        { id: "status-0", nombre: "Inactivo" },
    ]);
    const [loading, setLoading] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const userData = JSON.parse(sessionStorage.getItem("userData"));
    const tipoUsuID = userData?.tipoUsuID;
    const { config } = UseAppConfig();

    useEffect(() => {
        const fetchFilters = async () => {
            setLoading(true);
            try {
                const response = await getPostData(`${config.API_BASE_URL}/catalogo/${catalogo}`);
                const filteredResponse =
                    tipoUsuID === 2
                        ? response.filter((filter) => filter.nombre !== "Admin")
                        : response;

                setFilters(filteredResponse);
            } catch (error) {
                console.error("Error al obtener los filtros:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchFilters();
    }, [catalogo, id, config.API_BASE_URL, tipoUsuID]);

    const applyFilter = async () => {
        const filtersKeyValues = selectedFilters.map(Number);
        const statusValues = selectedStatus.map((statusId) =>
            Number(statusId.replace("status-", ""))
        );

        const payload = {
            [filterKey]: filtersKeyValues,
            status: statusValues,
        };
        console.log('Payload:', payload);

        try {
            const data = await sendPostData(`${config.API_BASE_URL}/filtrar/${filtro}`, payload);
            if (onApplyFilter) onApplyFilter(data.results || []);
        } catch (error) {
            console.error("Error al aplicar el filtro:", error);
        }
    };
    useEffect(() => {
        applyFilter();
    }, [selectedFilters, selectedStatus]);

    const handleCheckboxChange = (value) => {
        setSelectedFilters((prev) =>
            prev.includes(value) ? prev.filter((item) => item !== value) : [...prev, value]
        );
        handleClose();
    };

    const handleStatusChange = (value) => {
        setSelectedStatus((prev) =>
            prev.includes(value) ? prev.filter((item) => item !== value) : [...prev, value]
        );
        handleClose();
    };

    const handleToggle = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const clearFilters = () => {
        setSelectedFilters([]);
        setSelectedStatus([]);
        applyFilter();
        handleClose();
    };

    const open = Boolean(anchorEl);

    return (
        <ClickAwayListener onClickAway={handleClose}>
            <Box>
                <Button
                    variant="outlined"
                    onClick={handleToggle}
                    sx={{
                        textTransform: "none",
                        backgroundColor: "#fff",
                        borderColor: "#ccc",
                        width: "120px",
                        height: "41px",
                        fontSize: "15px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        position: "relative",
                        padding: "0 12px",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "8px",
                            position: "relative",
                            transition: "transform 0.2s ease",
                            transform:
                                selectedFilters.length + selectedStatus.length > 0
                                    ? "translateX(-10px)"
                                    : "translateX(0)",
                        }}
                    >
                        <img
                            alt="filtro-icon"
                            src={`${process.env.PUBLIC_URL}/assets/icons/navigation/filtro.svg`}
                            width="20px"
                            height="20px"
                            style={{ cursor: "pointer" }}
                        />
                        Filtrar
                    </Box>
                    {selectedFilters.length + selectedStatus.length > 0 && (
                        <Box
                            sx={{
                                backgroundColor: "#1e88e5",
                                color: "#fff",
                                borderRadius: "50%",
                                width: "20px",
                                height: "20px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                fontSize: "12px",
                                fontWeight: "bold",
                                position: "absolute",
                                right: "10px",
                            }}
                        >
                            {selectedFilters.length + selectedStatus.length}
                        </Box>
                    )}
                </Button>
                <Popper
                    open={open}
                    anchorEl={anchorEl}
                    role={undefined}
                    transition
                    placement="bottom-start"
                    style={{ zIndex: 1300 }}
                >
                    {({ TransitionProps }) => (
                        <Grow {...TransitionProps}>
                            <Paper
                                sx={{
                                    minWidth: "60px",
                                    p: 2,
                                    overflowY: "auto",
                                    borderRadius: "8px",
                                    display: "flex",
                                    flexDirection: "column", 
                                    gap: "16px",
                                    position: "relative",
                                    minHeight: `${30 + 
                                        (Math.max(filters.length, statusFilters.length) * 40) + 
                                        (selectedFilters.length + selectedStatus.length > 0 ? 60 : 0) 
                                        }px`,
                                    maxHeight: "500px",
                                }}
                            >
                                {loading ? (
                                    <Typography>Cargando...</Typography>
                                ) : (
                                    <Box
                                        sx={{
                                            flex: 1, 
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "space-between", 
                                            gap: "16px",
                                        }}
                                    >
                                        {filters.length > 0 && (
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    minWidth: "100px",
                                                }}
                                            >
                                                <Typography
                                                    variant="body2"
                                                    sx={{ fontWeight: "bold", mb: 1, fontSize: "13px" }}
                                                >
                                                    {filterText}
                                                </Typography>
                                                {filters.map((filter) => (
                                                    <FormControlLabel
                                                        key={filter.id}
                                                        control={
                                                            <Checkbox
                                                                checked={selectedFilters.includes(filter.id)}
                                                                onChange={() => handleCheckboxChange(filter.id)}
                                                                sx={{
                                                                    color: "gray",
                                                                    "&.Mui-checked": {
                                                                        color: "#1e88e5",
                                                                    },
                                                                }}
                                                            />
                                                        }
                                                        label={filter.nombre || `Filtro ${filter.id}`}
                                                    />
                                                ))}
                                            </Box>
                                        )}
                                        {statusFilters.length > 0 && (
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    minWidth: "100px",
                                                }}
                                            >
                                                <Typography
                                                    variant="body2"
                                                    sx={{ fontWeight: "bold", mb: 1, fontSize: "13px" }}
                                                >
                                                    Estatus
                                                </Typography>
                                                {statusFilters.map((status) => (
                                                    <FormControlLabel
                                                        key={status.id}
                                                        control={
                                                            <Checkbox
                                                                checked={selectedStatus.includes(status.id)}
                                                                onChange={() => handleStatusChange(status.id)}
                                                                sx={{
                                                                    color: "gray",
                                                                    "&.Mui-checked": {
                                                                        color: "#1e88e5",
                                                                    },
                                                                }}
                                                            />
                                                        }
                                                        label={status.nombre}
                                                    />
                                                ))}
                                            </Box>
                                        )}
                                    </Box>
                                )}
                                {(selectedFilters.length > 0 || selectedStatus.length > 0) && (
                                    <Box
                                        sx={{
                                            position: "absolute",
                                            bottom: "16px",
                                            right: "16px",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "flex-end", 
                                            gap: "8px",
                                            cursor: "pointer",
                                        }}
                                        onClick={clearFilters}
                                    >
                                        <img
                                            src={`${process.env.PUBLIC_URL}/assets/icons/navigation/ic-close.svg`}
                                            alt="Limpiar filtros"
                                            width="20"
                                            height="20"
                                        />
                                        <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                                            Limpiar Filtros
                                        </Typography>
                                    </Box>
                                )}
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </Box>
        </ClickAwayListener>
    );
};

export const TextFilter = ({ onSearch, data, placeholderText }) => {
    const [searchTerm, setSearchTerm] = useState("");

    const handleSearchChange = (event) => {
        const value = event.target.value.toLowerCase();
        setSearchTerm(value);

        if (onSearch) {
            const filteredData = data.filter((row) =>
                Object.values(row).some((field) =>
                    field?.toString().toLowerCase().includes(value)
                )
            );
            onSearch(filteredData);
        }
    };

    const handleClearSearch = () => {
        setSearchTerm("");
        if (onSearch) onSearch(data);
    };

    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                width: "350px",
                backgroundColor: "#fff",
                borderRadius: "8px",
                border: "1px solid #ccc",
                padding: "5px 12px",
                gap: "10px",
            }}
        >
            <img
                alt="buscar-icon"
                src={`${process.env.PUBLIC_URL}/assets/icons/navigation/buscar.svg`}
                width="20px"
                height="20px"
                style={{ cursor: "pointer" }}
            />
            <TextField
                fullWidth
                placeholder={placeholderText} // Placeholder dinámico
                variant="standard"
                value={searchTerm}
                onChange={handleSearchChange}
                InputProps={{
                    disableUnderline: true,
                }}
                sx={{
                    "& .MuiInputBase-root": {
                        margin: 0,
                        padding: 0,
                        backgroundColor: "transparent",
                    },
                    "& .MuiInput-underline:before, & .MuiInput-underline:after": {
                        display: "none",
                    },
                }}
            />
            {searchTerm && (
                <img
                    src={`${process.env.PUBLIC_URL}/assets/icons/navigation/ic-close.svg`}
                    alt="Limpiar búsqueda"
                    width="20"
                    height="20"
                    style={{ cursor: "pointer" }}
                    onClick={handleClearSearch}
                />
            )}
        </Box>
    );
};


