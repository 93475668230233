import { AppBar, Toolbar, Box, IconButton, Menu, MenuItem } from "@mui/material";
import { useState } from "react";
import { Link } from "react-router-dom";
import { UploadProfile, UploadLogoHeader } from "../globals/UploadImages";

const Header = ({ isCollapsed, setIsLoggedIn, selected, setSelected }) => {
    const sidebarWidth = isCollapsed ? 80 : 270;
    
    const [profileImage, setProfileImage] = useState(null);
    const userData = JSON.parse(sessionStorage.getItem("userData"));
    const id = userData?.id;
    const organizacionID = userData?.organizacionID;
    const nombre = userData?.nombre || "";
    const apellidos = userData?.apellidos || "";
    const usuarioPerfilImagen = userData?.usuarioPerfilImagen || "";
    const organizacionPerfilImagen = userData?.organizacionPerfilImagen || "";

    const handleLogout = () => {
        sessionStorage.removeItem("userData");
        setIsLoggedIn(false);
    };

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleImageUploadSuccess = (newImage) => {
        setProfileImage(newImage);
    };

    return (
        <AppBar
            position="fixed"
            sx={{
                backgroundColor: "#F4F6F8",
                boxShadow: "none",
                borderBottom: "2px solid #9f9f9f",
                width: `calc(100% - ${sidebarWidth}px)`,
                ml: `${sidebarWidth}px`,
                transition: "all 0.3s ease-in-out",
                zIndex: 9,
                height: "60px",
            }}
        >
            <Toolbar
                sx={{
                    minHeight: "50px",
                    display: "flex",
                    justifyContent: "space-between",
                    paddingX: "16px",
                    marginLeft: "29px",
                }}
            >
                <UploadLogoHeader
                    id={organizacionID}
                    perfilImagen={organizacionPerfilImagen}
                    inputId="upload-logo-header"
                />
                <Box display="flex" alignItems="center" gap="2px" marginRight={"15px"}>
                    <IconButton onClick={handleMenuOpen}>
                        <img
                            alt="add-icon"
                            src={`${process.env.PUBLIC_URL}/assets/icons/navigation/add-cl.svg`}
                            width="25px"
                            height="25px"
                        />
                    </IconButton>
                    <Menu
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleMenuClose}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "center",
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "center",
                        }}
                    >
                        <MenuItem
                            component={Link}
                            to="/projects"
                            onClick={() => {
                                setSelected(1);
                                handleMenuClose();
                            }}
                            className={selected === 1 ? "active" : ""}
                        >
                            <img
                                alt="add-budget-icon"
                                src={`${process.env.PUBLIC_URL}/assets/icons/navigation/add-presupuesto.svg`}
                                style={{ marginRight: "8px", width: "20px", height: "20px" }}
                            />
                            Crear presupuesto
                        </MenuItem>
                        <MenuItem
                            component={Link}
                            to="/articles"
                            onClick={() => {
                                setSelected(2);
                                handleMenuClose();
                            }}
                            className={selected === 2 ? "active" : ""}
                        >
                            <img
                                alt="add-article-icon"
                                src={`${process.env.PUBLIC_URL}/assets/icons/navigation/add-producto.svg`}
                                style={{ marginRight: "8px", width: "20px", height: "20px" }}
                            />
                            Agregar artículo
                        </MenuItem>
                    </Menu>
                    <IconButton onClick={handleLogout}>
                        <img
                            alt="logout-icon"
                            src={`${process.env.PUBLIC_URL}/assets/icons/navigation/logout.svg`}
                            width="25px"
                            height="25px"
                        />
                    </IconButton>
                    <UploadProfile
                        id={id}
                        perfilImagen={usuarioPerfilImagen}
                        setPerfilImagen={handleImageUploadSuccess}
                        nombre={nombre}
                        apellidos={apellidos}
                        inputId="upload-profile"
                    />
                </Box>
            </Toolbar>
        </AppBar>
    );
};

export default Header;
