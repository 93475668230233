import React from "react";
import App from "./app";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { SnackbarProvider } from 'notistack';
import { AppConfigProvider } from './system/config';
import { LogoProvider } from './components/hooks/LogoProvider';

import "./theme/index.css";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
    <AppConfigProvider>
        <SnackbarProvider
            maxSnack={5}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
        >
            <LogoProvider> 
                <React.StrictMode>
                    <BrowserRouter>
                        <App />
                    </BrowserRouter>
                </React.StrictMode>
            </LogoProvider>
        </SnackbarProvider>
    </AppConfigProvider>
);
