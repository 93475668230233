import { Box } from "@mui/material";

export const tableHeaders = [
    {
        field: "organizacionNombre",
        headerName: "Organización",
        headerAlign: "left",
        align: "left",
        width: 200,
    },
    {
        field: "usuarioDetalle",
        headerName: "Usuario",
        headerAlign: "left",
        align: "left",
        flex: 1,
        minWidth: 150, 
    },
    {
        field: "correo",
        headerName: "Correo Electrónico",
        headerAlign: "left",
        align: "left",
        flex: 1,
        minWidth: 200, 
    },
    {
        field: "tipoUsuID",
        headerName: "Perfil",
        headerAlign: "left",
        align: "left",
        width: 130,
    },
    {
        field: "status",
        headerName: "Activo",
        headerAlign: "center",
        align: "left",
        width: 80, 
        isSwitch: true,
    },
    {
        field: "acciones",
        headerName: "",
        headerAlign: "center",
        align: "center",
        width: 100,
        renderCell: () => (
            <Box display="flex" gap="15px">
                <img
                    src={`${process.env.PUBLIC_URL}/assets/icons/navigation/eliminar.svg`}
                    alt="Eliminar"
                    width="25"
                    height="25"
                    style={{ cursor: "pointer" }}
                />
                <img
                    src={`${process.env.PUBLIC_URL}/assets/icons/navigation/editar.svg`}
                    alt="Editar"
                    width="25"
                    height="25"
                    style={{ cursor: "pointer" }}
                />
            </Box>
        ),
    },
];

