import React, { useEffect, useState, useCallback } from "react";
import { SimpleDataTable } from "../../components/globals/DataTableXS";
import { formConfig } from "../../components/globals/useFormConfig";
import { UseAppConfig } from "../../system/config";
import { getPostData, sendPostData } from "../../system/getData";
import { Box, Typography } from "@mui/material";
import CustomSwitch from "../../components/globals/CustomSwitch";
import { UploadLogo } from "../../components/globals/UploadImages";

const OrganizationDetails = ({ dataConfig, refreshTable }) => {
    const { config } = UseAppConfig();
    const initValues = dataConfig?.initValues;
    const [status, setStatus] = React.useState(!!initValues?.status);

    if (!initValues?.id) return null;

    const handleSwitchChange = async (id, filtro, nuevoStatus) => {
        try {
            setStatus(nuevoStatus);

            const payload = { id, nuevoStatus };
            await sendPostData(`${config.API_BASE_URL}/actualizarStatus/${filtro}`, payload);
            console.log(`Estado actualizado correctamente: ID=${id}, Filtro=${filtro}, NuevoStatus=${nuevoStatus}`);

            if (refreshTable) {
                await refreshTable();
            }
        } catch (error) {
            console.error("Error al actualizar el estado:", error);
        }
    };

    return (
        <>
            <Box
                sx={{
                    width: "100%",
                    height: "70px",
                    backgroundColor: "#F2F2F2",
                    borderTopLeftRadius: 2,
                    borderTopRightRadius: 2,
                    position: "relative",
                    zIndex: 1,
                }}
            />
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    position: "relative",
                    zIndex: 2,
                    marginTop: "-60px",
                    padding: "16px",
                    gap: "14px",
                }}
            >
                    <UploadLogo
                        id={initValues?.id}
                        perfilImagen={initValues?.perfilImagen}
                        setPerfilImagen={(newImage) => {
                            initValues.perfilImagen = newImage;
                        }}
                        size={85}
                    />

                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                    }}
                >
                    <Typography
                        variant="h3"
                        sx={{
                            fontWeight: "bold",
                            marginBottom: "10px",
                        }}
                    >
                        {initValues?.nombre || "Nombre no disponible"}
                    </Typography>
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "8px",
                        }}
                    >
                        <img
                            alt="agregar-icon"
                            src={`${process.env.PUBLIC_URL}/assets/icons/navigation/graph-utilidad.svg`}
                            width="20px"
                            height="20px"
                        />

                        <Typography
                            variant="body2"
                            sx={{
                                color: "#1976d2",
                                fontSize: "15px"
                            }}
                        >
                            Factor de utilidad: {initValues?.factorUtilidad ?? "No disponible"}
                        </Typography>
                    </Box>
                </Box>
                <Box
                    sx={{
                        position: "relative",
                        width: "calc(100% + 32px)",
                        marginLeft: "-16px",
                        marginTop: "5px",
                        borderBottom: "2px solid #ddd",
                    }}
                />
                <Box
                    sx={{
                        position: "absolute",
                        top: "70px",
                        right: "10px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: "#F2F2F2",
                        borderRadius: "15px",
                        padding: "1px",
                    }}
                >
                    <CustomSwitch
                        id={initValues.id}
                        filtro="organizaciones"
                        checked={status}
                        onChange={handleSwitchChange}
                    />
                    <Typography
                        variant="body2"
                        color="textSecondary"
                        sx={{
                            marginRight: "10px",
                            marginTop: "3px",
                            marginLeft: "-5px",
                            fontSize: "14px",
                            fontWeight: "400",
                        }}
                    >
                        {status ? "Activo" : "Inactivo"}
                    </Typography>
                </Box>
            </Box>

        </>
    );
};

const Organizations = () => {
    const { config } = UseAppConfig();
    const [configData, setConfigData] = useState(null);
    const [columns, setColumns] = useState([]);

    const fetchGiros = useCallback(async () => {
        try {
            const response = await getPostData(`${config.API_BASE_URL}/catalogo/giro`);
            return response || [];
        } catch (error) {
            console.error("Error al obtener giros:", error);
            return [];
        }
    }, [config]);

    const generateColumns = useCallback(async (tableHeaders) => {
        const giros = await fetchGiros();

        const updatedColumns = tableHeaders.map((header) => {
            if (header.field === "fechaCreacion") {
                return {
                    ...header,
                    renderCell: (params) => {
                        if (!params.value) return "Sin fecha";

                        try {
                            const date = new Date(params.value);

                            if (isNaN(date.getTime())) {
                                console.error("Fecha inválida:", params.value);
                                return "Fecha inválida";
                            }

                            const day = date.getDate().toString().padStart(2, "0");
                            const month = date.toLocaleString("es-ES", { month: "short" }).replace(".", "");
                            const year = date.getFullYear();

                            return `${day} ${month.charAt(0).toUpperCase() + month.slice(1)}, ${year}`;
                        } catch (error) {
                            console.error("Error procesando la fecha:", error);
                            return "Error en fecha";
                        }
                    },
                };
            }

            if (header.field === "giro") {
                return {
                    ...header,
                    renderCell: (params) => {
                        const giro = giros.find((g) => g.id === params.value)?.nombre || "Giro desconocido";
                        return <span>{giro}</span>;
                    },
                };
            }

            if (header.field === "factorUtilidad") {
                return {
                    ...header,
                    renderCell: (params) => {
                        if (params.value == null || params.value === "") return "N/A";
                        return `${params.value}%`;
                    },
                };
            }

            return header;
        });

        setColumns(updatedColumns);
    }, [fetchGiros]);

    const fetchFormConfig = useCallback(
        async (type = "initialOrganization", data = null) => {
            try {
                const rawConfigData = await formConfig(type, data);

                console.log("Datos de configuración obtenidos:", rawConfigData);
                const endpoint =
                    rawConfigData.dataConfig?.getData || rawConfigData.endpoint;

                if (!endpoint) {
                    throw new Error(
                        `No se encontró un endpoint válido ni 'getData' en los datos de configuración para el tipo: ${type}`
                    );
                }
                const fullEndpoint = `${config.API_BASE_URL}${endpoint}`;
                const fullConfigData = {
                    ...rawConfigData,
                    dataConfig: {
                        ...rawConfigData.dataConfig,
                        endpoint: fullEndpoint,
                    },
                };

                if (type === "initialOrganization") {
                    await generateColumns(fullConfigData.dataConfig.tableHeaders || []);
                    setConfigData(fullConfigData);
                }

                return fullConfigData;
            } catch (error) {
                console.error("Error obteniendo formConfig:", error);
                throw error;
            }
        },
        [config, generateColumns]
    );

    useEffect(() => {
        fetchFormConfig();
    }, [fetchFormConfig]);

    return (
        <SimpleDataTable
            formConfig={{
                ...configData,
                dataConfig: {
                    ...configData?.dataConfig,
                    tableHeader: columns,
                },
            }}
            fetchFormConfig={fetchFormConfig}
            detailsComponent={OrganizationDetails}
        />
    );
};

export default Organizations;
