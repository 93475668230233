import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Box, Button, Typography, Paper } from "@mui/material";
import { UseAppConfig } from '../../system/config';
import { getPostData, sendPostData } from '../../system/getData';
import { TextFilter, FilterComponent } from "../globals/SearchFilterXS";
import { GenerarFormularioSimpleXS } from "../globals/GlobalFormsXS";
import DeleteModal from "../globals/DeleteObject";
import CustomSwitch from "../../components/globals/CustomSwitch";
import { useSnackbar } from "notistack";

export const SimpleDataTable = ({ formConfig, fetchFormConfig, detailsComponent }) => {
    const { catalogo, getData, filtro, filterKey, actionModule, placeholder, filterText } = formConfig?.dataConfig || {};
    const { config } = UseAppConfig();

    const { enqueueSnackbar } = useSnackbar();
    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = 6;

    const [rows, setRows] = useState([]);
    const [resetRows, setResetRows] = useState([]);
    const [filteredRows, setFilteredRows] = useState([]);
    const [modalConfig, setModalConfig] = useState(null);

    const [openModal, setOpenModal] = useState(false);
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const [deleteModalConfig, setDeleteModalConfig] = useState(null)

    const [editValues, setEditValues] = useState(null);

    const [loading, setLoading] = useState(true);
    const [isSwitchUpdating, setIsSwitchUpdating] = React.useState(false);

    const fetchData = async () => {
        try {
            setLoading(true);

            const userData = JSON.parse(sessionStorage.getItem("userData"));
            const tipoUsuID = userData?.tipoUsuID;
            const organizacionID = userData?.organizacionID;

            if (!tipoUsuID || !organizacionID) {
                enqueueSnackbar("Error: No se encontró información del usuario.", { variant: "error" });
                setLoading(false);
                return;
            }
            const data = await getPostData(`${config.API_BASE_URL}/${getData}`, { tipoUsuID, organizacionID });

            setRows(data || []);
            setResetRows(data || []);
            setFilteredRows(data || []);
        } catch (error) {
            console.error("Error al cargar datos:", error);
            enqueueSnackbar("Error al cargar los datos", { variant: "error" });
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (getData) {
            fetchData();
        }
    }, [getData, config]);

    const paginatedRows = filteredRows.slice(
        (currentPage - 1) * pageSize,
        currentPage * pageSize
    );

    const totalPages = Math.ceil(filteredRows.length / pageSize);

    const handlePreviousPage = () => {
        if (currentPage > 1) setCurrentPage(currentPage - 1);
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) setCurrentPage(currentPage + 1);
    };

    const refreshTable = async () => {
        try {
            const userData = JSON.parse(sessionStorage.getItem("userData"));
            const tipoUsuID = userData?.tipoUsuID;
            const organizacionID = userData?.organizacionID;
            const updatedData = await getPostData(`${config.API_BASE_URL}/${getData}`, { tipoUsuID, organizacionID });

            setRows(updatedData || []);
            setResetRows(updatedData || []);
            setFilteredRows(updatedData || []);
        } catch (error) {
            console.error("Error al refrescar los datos:", error);
            enqueueSnackbar("Error al refrescar los datos", { variant: "error" });
        }
    };

    const handleApplyFilter = (filteredData) => {
        if (!filteredData.length) {
            setFilteredRows(resetRows);
        } else {
            setFilteredRows(filteredData);
        }
        setCurrentPage(1);
    };

    const handleOpenModal = async (action, actionModule, data = null) => {
        try {
            const actionType = `${action}${actionModule}`;
            const formConfigOverride = await fetchFormConfig(actionType, data);
            const completeEndpoint = formConfigOverride.endpoint.startsWith(config.API_BASE_URL)
                ? formConfigOverride.endpoint
                : `${config.API_BASE_URL}${formConfigOverride.endpoint}`;

            console.log("Endpoint configurado correctamente:", completeEndpoint);

            setEditValues(data || {});
            setModalConfig({
                ...formConfigOverride.dataConfig,
                endpoint: completeEndpoint,
            });
            setOpenModal(true);
        } catch (error) {
            console.error("Error al configurar el formulario:", error);
        }
    };

    const handleOpenConfirmModal = async (action, actionModule, data = null, closeCurrentModal = null) => {
        try {
            if (closeCurrentModal) closeCurrentModal();
            const actionType = `${action}${actionModule}`;
            const formConfigOverride = await fetchFormConfig(actionType, data);

            const completeEndpoint = formConfigOverride.endpoint.startsWith(config.API_BASE_URL)
                ? formConfigOverride.endpoint
                : `${config.API_BASE_URL}${formConfigOverride.endpoint}`;

            setDeleteModalConfig({
                ...formConfigOverride,
                endpoint: completeEndpoint,
                dataId: data?.id,
                idField: formConfigOverride.idField || "id",
            });

            setConfirmModalOpen(true);
        } catch (error) {
            console.error("Error al abrir el modal de confirmación:", error);
        }
    };

    const handleSwitchChange = async (id, filtro, nuevoStatus) => {
        if (isSwitchUpdating) {
            console.log("Ya hay una actualización en curso. Ignorada.");
            return; 
        }
    
        setIsSwitchUpdating(true); 
    
        try {
            setRows((prevRows) =>
                prevRows.map((row) =>
                    row.id === id ? { ...row, status: nuevoStatus } : row
                )
            );
    
            const payload = { id, nuevoStatus };
            await sendPostData(`${config.API_BASE_URL}/actualizarStatus/${filtro}`, payload);

            await refreshTable();
    
            console.log(`Estado actualizado correctamente: ID=${id}, Filtro=${filtro}, NuevoStatus=${nuevoStatus}`);
    
            enqueueSnackbar("Estado actualizado correctamente", { variant: "success" });
        } catch (error) {
            console.error("Error al actualizar el estado:", error);
            enqueueSnackbar("Error al actualizar el estado", { variant: "error" });
        } finally {
            setIsSwitchUpdating(false); 
        }
    };

    const enhancedColumns = React.useMemo(() => {
        const baseColumns = formConfig?.dataConfig?.tableHeader || [];
        return baseColumns.map((column) => {
            if (column.field === "acciones") {
                return {
                    ...column,
                    renderCell: (params) => (
                        <Box display="flex" gap="15px">
                            <img
                                src={`${process.env.PUBLIC_URL}/assets/icons/navigation/eliminar.svg`}
                                alt="Eliminar"
                                width="25"
                                height="25"
                                style={{ cursor: "pointer" }}
                                onClick={() => handleOpenConfirmModal("delete", actionModule, params.row)}
                            />
                            <img
                                src={`${process.env.PUBLIC_URL}/assets/icons/navigation/editar.svg`}
                                alt="Editar"
                                width="25"
                                height="25"
                                style={{ cursor: "pointer" }}
                                onClick={() => handleOpenModal("edit", actionModule, params.row)}
                            />
                        </Box>
                    ),
                };
            }
            if (column.field === "status") {
                return {
                    ...column,
                    renderCell: (params) => (
                        <CustomSwitch
                            id={params.row.id}
                            filtro={formConfig?.dataConfig?.filtro}
                            checked={!!params.row.status}
                            onChange={(id, filtro, nuevoStatus) =>
                                handleSwitchChange(id, filtro, nuevoStatus)
                            }
                        />
                    ),
                };
            }

            return column;
        });
    }, [formConfig, handleOpenConfirmModal, handleOpenModal]);

    return (
        <Box sx={{ backgroundColor: "#F4F6F8", padding: "20px" }}>
            <Typography
                variant="h3"
                sx={{
                    fontWeight: "bold",
                    marginBottom: "24px",
                    textAlign: "left",
                }}
            >
                {formConfig?.dataConfig?.title || "Tabla"}
            </Typography>
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: "16px",
                    marginBottom: "16px",
                }}
            >
                <Box sx={{ display: "flex", alignItems: "center", gap: "16px" }}>
                    <TextFilter
                        data={resetRows}
                        onSearch={(filteredData) => {
                            setFilteredRows(filteredData);
                            setCurrentPage(1);
                        }}
                        placeholderText={placeholder}
                    />
                    <FilterComponent
                        catalogo={catalogo}
                        onApplyFilter={handleApplyFilter}
                        filtro={filtro}
                        filterKey={filterKey}
                        filterText={filterText}
                    />
                </Box>
                <Button
                    variant="contained"
                    onClick={() => handleOpenModal("add", actionModule)}
                    sx={{
                        textTransform: "none",
                        backgroundColor: "#1976d2",
                        color: "#fff",
                        fontSize: "15px",
                        gap: "8px",
                        "&:hover": { backgroundColor: "#155fa0" },
                    }}
                >
                    <img
                        alt="agregar-icon"
                        src={`${process.env.PUBLIC_URL}/assets/icons/navigation/add-cl.svg`}
                        width="20px"
                        height="20px"
                        style={{ cursor: "pointer", filter: "brightness(100)" }}
                    />
                    Agregar
                </Button>
            </Box>
            {loading ? (
                <Box>

                </Box>
            ) :
                rows.length === 0 ? (
                    <Box

                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            height: "60vh",
                            textAlign: "center",
                        }}
                    >

                        <img
                            src={`${process.env.PUBLIC_URL}/assets/icons/navigation/empty-org-img.png`}
                            alt="Sin datos"
                            width="130"
                            height="120"
                        />
                        <Typography variant="h4" sx={{ marginTop: "40px", marginBottom: "15px" }}>
                            No se han encontrado organizaciones
                        </Typography>
                        <Typography variant="body1" sx={{ color: "gray", marginBottom: "24px" }}>
                            Agrega una organización para continuar
                        </Typography>

                        <Button
                            variant="contained"
                            onClick={() => handleOpenModal("add", actionModule)}
                            sx={{
                                height: "40px",
                                textTransform: "none",
                                backgroundColor: "#1976d2",
                                color: "#fff",
                                fontSize: "14px",
                                gap: "8px",
                                borderRadius: "10px",
                                "&:hover": { backgroundColor: "#155fa0" },
                            }}

                        >
                            <img
                                alt="agregar-icon"
                                src={`${process.env.PUBLIC_URL}/assets/icons/navigation/add-cl.svg`}
                                width="20px"
                                height="20px"
                                style={{ cursor: "pointer", filter: "brightness(100)" }}
                            />
                            Agregar {formConfig?.dataConfig?.title || "Organización"}
                        </Button>

                    </Box>
                ) : (
                    <>
                        <Box component={Paper} style={{ width: "100%" }}>
                            <DataGrid
                                rows={paginatedRows.map((row, index) => ({ id: row.id || index, ...row }))}
                                columns={enhancedColumns.map((column) => ({
                                    ...column,
                                    width: column.width || undefined,
                                    minWidth: column.width || 100,
                                    flex: column.width ? undefined : 1
                                }))}
                                disableSelectionOnClick
                                disableColumnMenu
                                hideFooterPagination
                                autoHeight
                                loading={formConfig?.loading || false}
                                disableColumnSelector
                                disableColumnSorting
                                disableRowSelectionOnClick
                                components={{
                                    NoRowsOverlay: () => (
                                        <Box
                                            sx={{
                                                display: "flex",
                                                flexDirection: "column",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                height: "100%",
                                                textAlign: "center",
                                            }}
                                        >
                                            <Typography variant="h5">
                                                No se encontró {formConfig?.dataConfig?.title || 'registros'}
                                            </Typography>
                                        </Box>
                                    ),
                                }}
                                sx={{
                                    "& .MuiDataGrid-root": {
                                        overflowX: "auto",
                                    },
                                    "& .MuiDataGrid-cell": {
                                        borderBottom: "1px solid #E0E0E0",
                                        justifyContent: "flex-start",
                                        textAlign: "left",
                                        fontSize: "12px",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        whiteSpace: "nowrap",
                                    },
                                    "& .MuiDataGrid-columnHeaders": {
                                        borderBottom: "1px solid #E0E0E0",
                                        pointerEvents: "none",
                                        justifyContent: "flex-start",
                                        textAlign: "left",
                                        fontSize: "13px",
                                        fontWeight: "bold",
                                    },
                                    "& .MuiDataGrid-columnSeparator": {
                                        display: "none",
                                    },
                                    "& .MuiDataGrid-row:hover": {
                                        backgroundColor: "transparent",
                                    },
                                    "& .MuiDataGrid-footerContainer": {
                                        display: "none",
                                    },
                                    "& .MuiDataGrid-row.Mui-selected": {
                                        backgroundColor: "transparent !important",
                                    },
                                    "& .MuiDataGrid-cell:focus, & .MuiDataGrid-columnHeader:focus": {
                                        outline: "none",
                                    },
                                }}
                            />
                        </Box>
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                marginTop: "16px",
                                gap: "8px",
                            }}
                        >
                            <Button
                                onClick={handlePreviousPage}
                                disabled={currentPage === 1}
                                sx={{
                                    border: "1px solid #ccc",
                                    borderRadius: "8px",
                                    gap: "10px",
                                    padding: "6px 12px",
                                    textTransform: "none",
                                    backgroundColor: currentPage === 1 ? "#f9f9f9" : "#fff",
                                    "&:hover": {
                                        backgroundColor: "#f5f5f5",
                                    },
                                    "&.Mui-disabled": {
                                        color: "#aaa",
                                    },
                                }}
                            >
                                <img
                                    src={`${process.env.PUBLIC_URL}/assets/icons/navigation/arrow-left.svg`}
                                    alt="Flecha izquierda"
                                    width="16"
                                    height="16"
                                />
                                Anterior
                            </Button>
                            {Array.from({ length: totalPages }, (_, index) => {
                                const pageNumber = index + 1;
                                return (
                                    <Box
                                        key={pageNumber}
                                        onClick={() => setCurrentPage(pageNumber)}
                                        sx={{
                                            cursor: "pointer",
                                            width: "35px",
                                            height: "35px",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            borderRadius: "4px",
                                            backgroundColor: currentPage === pageNumber ? "#000" : "transparent",
                                            color: currentPage === pageNumber ? "#fff" : "#000",
                                            border: currentPage === pageNumber ? "none" : "1px solid #ccc",
                                        }}
                                    >
                                        {pageNumber}
                                    </Box>
                                );
                            })}
                            <Button
                                onClick={handleNextPage}
                                disabled={currentPage === totalPages}
                                sx={{
                                    border: "1px solid #ccc",
                                    gap: "10px",
                                    borderRadius: "8px",
                                    padding: "7px 12px",
                                    textTransform: "none",
                                    backgroundColor: currentPage === totalPages ? "#f9f9f9" : "#fff",
                                    "&:hover": {
                                        backgroundColor: "#cdeeff",
                                    },
                                    "&.Mui-disabled": {
                                        color: "#aaa",
                                    },
                                }}
                            >
                                Siguiente
                                <img
                                    src={`${process.env.PUBLIC_URL}/assets/icons/navigation/arrow-right.svg`}
                                    alt="Flecha derecha"
                                    width="16"
                                    height="16"
                                />
                            </Button>
                        </Box>
                    </>
                )}
            <GenerarFormularioSimpleXS
                open={openModal}
                handleClose={() => {
                    setOpenModal(false);
                    setEditValues(null);
                    setModalConfig(null);
                    refreshTable();
                }}
                title={modalConfig?.title}
                initValues={editValues || modalConfig?.initValues}
                formConfig={{
                    handleOpenConfirmModal,
                    handleSwitchChange,
                    refreshTable,
                    ...modalConfig,
                }}
                detailsComponent={detailsComponent}
                validateSchemas={modalConfig?.validateSchemas}
                onSubmit={async (values) => {
                    try {
                        const response = await getPostData(`${modalConfig?.endpoint}`, values);
                        enqueueSnackbar(modalConfig?.successMessage || "Operación exitosa", { variant: "success" });
                        refreshTable();
                        setOpenModal(false);
                    } catch (error) {
                        console.error("Error al enviar el formulario:", error);
                        enqueueSnackbar(modalConfig?.errorMessage || "Error al procesar la operación", {
                            variant: "error",
                        });
                    }
                }}
                refreshTable={refreshTable}
            />

            <DeleteModal
                open={confirmModalOpen}
                onClose={() => {
                    setConfirmModalOpen(false);
                    setDeleteModalConfig(null);
                }}
                title={deleteModalConfig?.title || "¿Estás seguro?"}
                description={deleteModalConfig?.description || "Esta acción no se puede deshacer."}
                endpoint={deleteModalConfig?.endpoint}
                dataId={deleteModalConfig?.dataId}
                apiBaseUrl={config.API_BASE_URL}
                successMessage={deleteModalConfig?.successMessage}
                errorMessage={deleteModalConfig?.errorMessage}
                onSuccess={refreshTable}
            />
        </Box>
    );
};