import { loginFields } from "../../data/forms/login";
import { GeneraFormularioAutenticacion } from "../../components/globals/globalForms";

import * as yup from "yup";
import { useSnackbar } from "notistack";

import { UseAppConfig } from "../../system/config";
import { getPostData } from "../../system/getData";
import { useLogo } from '../hooks/LogoProvider';

const Login = ({ onLogin }) => {
    const { enqueueSnackbar } = useSnackbar();
    const { setLogoUrlForOrganization } = useLogo();

    const dataConfig = {
        title: "Iniciar sesión",
        colorBtn: "#1D74D3",
        titleBtn: "Iniciar sesión",
        fields: loginFields,
        showPasswordLink: true,
    };

    const initValues = {
        correo: "",
        password: "",
    };

    const validateSchemas = yup.object().shape({
        correo: yup.string().required("Inserte un correo"),
        password: yup.string().required("Inserte una contraseña"),
    });

    const { config } = UseAppConfig();

    const handleFormSubmit = async (values) => {
        try {
            const response = await getPostData(`${config.API_BASE_URL}/iniciarSesion`, values);
            if (response && response.length > 0) {
                const user = response[0];
                if (user.status === 2) {
                    enqueueSnackbar("Este usuario está desactivado. No puedes iniciar sesión.", { variant: "error" });
                } else {
                    const userData = {
                        id: user.userID,
                        correo: user.correo,
                        tipoUsuID: user.tipoUsuID,
                        organizacionID: user.organizacionID,
                        nombre: user.nombre,
                        apellidos: user.apellidos,
                        perfilImagen: user.usuarioPerfilImagen,
                        organizacionPerfilImagen: user.organizacionPerfilImagen,
                    };
                    sessionStorage.setItem("userData", JSON.stringify(userData));
                    const organizationLogoUrl = `${config.API_BASE_URL}/images/organizaciones/${user.organizacionPerfilImagen}`;
                    setLogoUrlForOrganization(user.organizacionID, organizationLogoUrl);
    
                    onLogin(
                        user.userID,
                        user.correo,
                        user.tipoUsuID,
                        user.organizacionID,
                        user.nombre,
                        user.apellidos,
                        user.usuarioPerfilImagen,
                        user.organizacionPerfilImagen
                    );
                    console.log("Datos almacenados:", userData);
                    enqueueSnackbar("Inicio de sesión exitoso", { variant: "success" });
                }
            } else {
                enqueueSnackbar("Usuario o contraseña incorrectos", { variant: "error" });
            }
        } catch (error) {
            enqueueSnackbar("Usuario o contraseña incorrectos", { variant: "error" });
            console.error("Error en la autenticación:", error);
        }
    };    

    return (
        <GeneraFormularioAutenticacion
            data={dataConfig}
            initValues={initValues}
            validateSchemas={validateSchemas}
            handleFormSubmit={handleFormSubmit}
        />
    );
};

export default Login;
