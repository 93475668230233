import * as yup from "yup";
import { categoriesFields } from "../forms/categories";
import { tableHeaders } from "../tableHeaders/categories";

export const categoriesConfig = (categoryData = null) => {
    const initValues = {
        nombre: categoryData?.nombre || "",
        descripcion: categoryData?.descripcion || "",
        organizacionID: categoryData?.organizacionID || null,
        factorUtilidad: categoryData?.factorUtilidad || 0.0,
        status: categoryData?.status || 1,
        fechaCreacion: categoryData?.fechaCreacion || null,
    };

    const dataConfig = {
        title: "Categorías",
        module: "categories",
        actionModule: "Category",
        catalogo: "categorias",
        filtro: "categorias",
        filterKey: "",
        placeholder: "Buscar categoria, descripción y factor de utilidad",
        gridTemplateColumns: "1fr 1fr",
        getData: "listado/categorias",
        tableHeader: tableHeaders,
        fields: categoriesFields,
    };

    const validateSchemas = yup.object().shape({
        nombre: yup.string().required("El nombre de la organización es requerido").matches(/^[a-zA-ZáéíóúÁÉÍÓÚñÑ\s]+$/, "La organizacion debe de ser Alfanumérico"),
        factorUtilidad: yup
            .number()
            .required("El factor de utilidad es requerido")
            .test('is-number', 'El valor debe ser un número', (value) => !isNaN(value))
            .test('max-decimals', 'El valor debe tener solo dos decimales', (value) => {
                return /^(\d+(\.\d{0,2})?)?$/.test(value);
            }),
    });

    return { initValues, validateSchemas, dataConfig };
};
