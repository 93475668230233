import * as yup from "yup";
import { organizationsFields } from "../forms/organizations";
import { tableHeaders } from "../../data/tableHeaders/organizations";

export const organizationsConfig = (orgData = null) => {
    const initValues = {
        nombre: orgData?.nombre || "",
        representante: orgData?.representante || "",
        giro: orgData?.giro || null,
        correo: orgData?.correo || "",
        telefono: orgData?.telefono || "",
        factordeutilidad: orgData?.factordeutilidad || null,
        fechaCreacion: orgData?.fechaCreacion || null,
        perfilImagen: orgData?.perfilImagen || null,
    };

    const dataConfig = {
        title: "Organizaciones",
        module: "organizations",
        actionModule:"Organization",
        catalogo: "giro",
        filtro: "organizaciones",
        filterKey: "giroIDs",
        filterText: "Giro",
        placeholder: "Buscar organizacion, correo eletrónico y utilidad",
        placeholderCB: "Seleccionar giro...",
        getData: "listado/organizaciones",
        gridTemplateColumns: "1fr 1fr",
        fields: organizationsFields,
        tableHeaders: tableHeaders,
    };

    const validateSchemas = yup.object().shape({
        nombre: yup.string().required("El nombre de la organización es requerido").matches(/^[a-zA-ZáéíóúÁÉÍÓÚñÑ\s]+$/, "El nombre debe de ser Alfanumérico"),
        representante: yup.string().required("El representante de la organización es requerido").matches(/^[a-zA-ZáéíóúÁÉÍÓÚñÑ\s]+$/, "El nombre del representante debe de ser Alfanumérico"),
        correo: yup
            .string()
            .email("El correo es inválido")
            .required("El correo es requerido"),
        telefono: yup
            .string()
            .notRequired()
            .test(
                "es-numero-valido",
                "El teléfono debe contener exactamente 10 dígitos y solo números",
                (value) => {
                    if (!value) return true;
                    return /^\d{10}$/.test(value);
                }
            ),
        factorUtilidad: yup
            .number()
            .required("El factor de utilidad es requerido")
            .test('is-number', 'El valor debe ser un número', (value) => !isNaN(value))
            .test('max-decimals', 'El valor debe tener solo dos decimales', (value) => {
                return /^(\d+(\.\d{0,2})?)?$/.test(value);
        }),
    });

    return { initValues, validateSchemas, dataConfig };
};
