import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, IconButton } from "@mui/material";
import { Link } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import dataMenu from "../../data/menu";

const Item = ({ id, title, to, icon, selected, setSelected }) => {
    return (
        <MenuItem
            active={selected === id}
            onClick={() => setSelected(id)}
            style={{
                margin: "2px 10px",
                display: "flex",
                alignItems: "center",
                gap: "10px",
                fontSize: "16px",
                fontWeight: "normal",
            }}
            icon={
                <img
                    src={process.env.PUBLIC_URL + icon}
                    alt={title}
                    style={{ width: 25, height: 25 }}
                />
            }
        >
            {title}
            <Link to={to} />
        </MenuItem>
    );
};

const Sidebar = ({ isCollapsed, setIsCollapsed, selected, setSelected }) => {
    const userData = JSON.parse(sessionStorage.getItem("userData"));
    const tipoUsuario = userData?.tipoUsuID;

    const mainMenu =
        tipoUsuario === 3
            ? dataMenu.filter((menuItem) => ![3, 4, 5, 6, 7].includes(menuItem.id))
            : dataMenu.filter((menuItem) => menuItem.id !== 7);

    const ajustesItem = dataMenu.find((menuItem) => menuItem.id === 7);

    return (
        <Box
            sx={{
                position: "fixed",
                height: "100%",
                width: {
                    xs: isCollapsed ? "60px" : "180px",
                    sm: isCollapsed ? "70px" : "200px",
                    md: isCollapsed ? "80px" : "250px",
                },
                backgroundColor: "#fff",
                borderRadius: "0 10px 0 0",
                zIndex: 10,
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                transition: "width 0.3s ease-in-out",
                "& .pro-sidebar-inner": {
                    backgroundColor: "#fff !important",
                },
                "& .pro-icon-wrapper": {
                    backgroundColor: "transparent !important",
                },
                "& .pro-inner-item": {
                    padding: "0 12px !important",
                    color: "#333",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "start",
                    width: "100%",
                    height: "50px",
                    borderRadius: "10px",
                },
                "& .pro-menu-item.active": {
                    backgroundColor: "#d8e9fd",
                    color: "#333 !important",
                    borderRadius: "10px",
                },
                "& .pro-inner-item:hover": {
                    backgroundColor: "#f1f1f1",
                    color: "#333 !important",
                    cursor: "pointer",
                    borderRadius: "10px",
                },
                "& .pro-arrow": {
                    "&:hover": {
                        backgroundColor: "#f0f0f0",
                    },
                },
            }}
        >
            <ProSidebar collapsed={isCollapsed}>
                <Menu iconShape="square">
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            height: "60px",
                            padding: "0 20px",
                            position: "relative",
                        }}
                    >
                        <IconButton
                            onClick={() => setIsCollapsed(!isCollapsed)}
                            sx={{
                                position: "absolute",
                                left: isCollapsed ? "10px" : "20px",
                                width: isCollapsed ? "60px" : "40px",
                                height: "40px",
                                borderRadius: "10px",
                                backgroundColor: "transparent",
                                transition: "all 0.3s ease",
                                "&:hover": {
                                    backgroundColor: "#f0f0f0",
                                },
                                "&::before": {
                                    content: '""',
                                    position: "absolute",
                                    top: 0,
                                    left: 0,
                                    width: isCollapsed ? "100px" : "40px", // Expande el área de hover cuando los items están retraídos
                                    height: "40px",
                                    borderRadius: "10px",
                                    backgroundColor: "transparent",
                                    zIndex: -1,
                                },
                                transform: "translateY(-50%)",
                                top: "50%",
                            }}
                        >
                            <img
                                src={
                                    isCollapsed
                                        ? `${process.env.PUBLIC_URL}/assets/icons/navigation/arrow-right.svg`
                                        : `${process.env.PUBLIC_URL}/assets/icons/navigation/arrow-left.svg`
                                }
                                alt="Toggle Sidebar"
                                style={{ width: 25, height: 25 }}
                            />
                        </IconButton>

                        {!isCollapsed && (
                            <img
                                src={`${process.env.PUBLIC_URL}/assets/icons/navigation/PU-logo.svg`}
                                alt="PU Logo"
                                style={{
                                    height: "25px",
                                    pointerEvents: "none",
                                    marginLeft: "50px",
                                }}
                            />
                        )}
                    </Box>
                    <Box>
                        {mainMenu.map((menuItem) => (
                            <Item
                                key={menuItem.id}
                                id={menuItem.id}
                                title={menuItem.title}
                                to={menuItem.dir}
                                icon={menuItem.icon}
                                selected={selected}
                                setSelected={setSelected}
                            />
                        ))}
                    </Box>
                    {ajustesItem && (
                        <Box
                            sx={{
                                position: "absolute",
                                bottom: 5,
                                left: 0,
                                width: "100%",
                                padding: "0",
                                "& .pro-menu-item": {
                                    margin: "0",
                                },
                                "& .pro-inner-item": {
                                    padding: "0 12px !important",
                                    color: "#333",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "start",
                                    width: "100%",
                                    height: "50px",
                                    borderRadius: "10px",
                                },
                                "& .pro-menu-item.active": {
                                    backgroundColor: "#d8e9fd",
                                    color: "#333 !important",
                                    borderRadius: "10px",
                                },
                                "& .pro-inner-item:hover": {
                                    backgroundColor: "#f1f1f1",
                                    color: "#333 !important",
                                    cursor: "pointer",
                                    borderRadius: "10px",
                                },
                            }}
                        >
                            <Item
                                key={ajustesItem.id}
                                id={ajustesItem.id}
                                title={ajustesItem.title}
                                to={ajustesItem.dir}
                                icon={ajustesItem.icon}
                                selected={selected}
                                setSelected={setSelected}
                            />
                        </Box>
                    )}
                </Menu>
            </ProSidebar>
        </Box>
    );
};

export default Sidebar;
