import React, { useState } from 'react';
import { UseAppConfig } from "../../system/config";
import { sendImage, sendPostData } from "../../system/getData";
import { IconButton, Avatar, Box, Typography, CircularProgress, Button } from '@mui/material';
import { useLogo } from '../hooks/LogoProvider';

export const UploadProfile = ({ id, perfilImagen = '', setPerfilImagen, nombre, apellidos, size = 45, inputId = 'upload-profile', }) => {
    const { config } = UseAppConfig();
    const [loading, setLoading] = useState(false);
    const [showCameraIcon, setShowCameraIcon] = useState(false);
    const [uniqueUrl, setUniqueUrl] = useState('');

    const handleFileUploadProfile = async (event) => {
        const file = event.target.files[0];
        if (!file) return;

        console.log('Archivo cargado:', file);

        const formData = new FormData();
        formData.append('perfilImagen', file);
        formData.append('id', id);

        setLoading(true);

        try {
            const response = await sendImage(
                `${config.API_BASE_URL}/actualizarImagen/usuarios`,
                formData
            );

            console.log('Respuesta de la API:', response);

            if (response.success) {
                setPerfilImagen(response.archivo);
                const newUrl = `${config.API_BASE_URL}/images/usuarios/${response.archivo}?t=${Date.now()}`;
                setUniqueUrl(newUrl); // Incrementa el key para forzar la recarga
            } else {
                alert("Error al cargar la imagen");
            }
        } catch (error) {
            alert("Error en la conexión");
            console.error("Error de conexión:", error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            <label htmlFor={inputId} style={{ cursor: 'pointer' }}>
                <input
                    id={inputId}
                    type="file"
                    accept="image/*"
                    style={{ display: 'none' }}
                    onChange={handleFileUploadProfile}
                />
                <IconButton component="span" sx={{ position: 'relative', padding: 0 }}>
                    <div
                        style={{
                            position: 'relative',
                            width: `${size}px`,
                            height: `${size}px`,
                            borderRadius: '50%',
                            overflow: 'hidden',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            cursor: 'pointer',
                        }}
                        onMouseEnter={() => setShowCameraIcon(true)}
                        onMouseLeave={() => setShowCameraIcon(false)}
                    >
                        {perfilImagen ? (
                            <img
                                alt="profile"
                                src={uniqueUrl || `${config.API_BASE_URL}/images/usuarios/${perfilImagen}`}
                                onError={() => console.log('Error cargando la imagen')}
                                className="profile-image"
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'cover',
                                    transition: 'filter 0.3s ease-in-out',
                                    filter: showCameraIcon ? 'brightness(0.4)' : 'brightness(1)',
                                }}
                            />
                        ) : (
                            <Avatar
                                sx={{
                                    backgroundColor: '#1976D2',
                                    width: '100%',
                                    height: '100%',
                                    fontSize: size * 0.35,
                                    transition: 'filter 0.3s ease-in-out',
                                    filter: showCameraIcon ? 'brightness(0.4)' : 'brightness(1)',
                                }}
                            >
                                {nombre && apellidos ? `${nombre[0]}${apellidos[0]}` : '?'}
                            </Avatar>
                        )}

                        <img
                            alt="upload-icon"
                            src={`${process.env.PUBLIC_URL}/assets/icons/navigation/cam.svg`}
                            className="upload-icon"
                            style={{
                                position: 'absolute',
                                width: `${size * 0.45}px`,
                                height: `${size * 0.45}px`,
                                filter: 'invert(100%)',
                                opacity: showCameraIcon ? 1 : 0,
                                transition: 'opacity 0.3s ease-in-out',
                                pointerEvents: 'none',
                                zIndex: 1,
                            }}
                        />
                    </div>
                </IconButton>
            </label>
        </div>
    );
};

export const UploadLogo = ({ id, size = 45, inputId = 'upload-logo' }) => {
    const { config } = UseAppConfig();
    const { getLogoUrlForOrganization, setLogoUrlForOrganization } = useLogo();
    const [loading, setLoading] = useState(false);
    const [showCameraIcon, setShowCameraIcon] = useState(false);
    const logoUrl = getLogoUrlForOrganization(id);

    const handleFileUploadProfile = async (event) => {
        const file = event.target.files[0];
        if (!file) return;

        console.log('Archivo cargado:', file);

        const formData = new FormData();
        formData.append('perfilImagen', file);
        formData.append('id', id);

        setLoading(true);

        try {
            const response = await sendImage(
                `${config.API_BASE_URL}/actualizarImagen/organizaciones`,
                formData
            );

            if (response.success) {
                console.log('Imagen actualizada en el servidor:', response.archivo);
                const updatedLogoResponse = await sendPostData(
                    `${config.API_BASE_URL}/imagen/organizaciones`,
                    { id }
                );

                if (updatedLogoResponse.success) {
                    const imageName = updatedLogoResponse.imageName; 
                    const imageUrl = `${config.API_BASE_URL}/images/organizaciones/${imageName}`; 
                    console.log('URL actualizada:', imageUrl);
                    setLogoUrlForOrganization(id, imageUrl);
                } else {
                    console.error('Error al obtener el nombre de la imagen:', updatedLogoResponse.message);
                }
            } else {
                alert('Error al cargar la imagen');
            }
        } catch (error) {
            alert('Error en la conexión');
            console.error('Error de conexión:', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            <label htmlFor={inputId} style={{ cursor: 'pointer' }}>
                <input
                    id={inputId}
                    type="file"
                    accept="image/*"
                    style={{ display: 'none' }}
                    onChange={handleFileUploadProfile}
                />
                <IconButton component="span" sx={{ position: 'relative', padding: 0 }}>
                    <div
                        style={{
                            position: 'relative',
                            width: `${size}px`,
                            height: `${size}px`,
                            borderRadius: '50%',
                            overflow: 'hidden',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            cursor: 'pointer',
                        }}
                        onMouseEnter={() => setShowCameraIcon(true)}
                        onMouseLeave={() => setShowCameraIcon(false)}
                    >
                        {logoUrl ? (
                            <img
                                alt="perfil"
                                src={logoUrl}
                                onError={() => console.log('Error cargando la imagen')}
                                className="perfil-image"
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'cover',
                                    transition: 'filter 0.3s ease-in-out',
                                    filter: showCameraIcon ? 'brightness(0.4)' : 'brightness(1)',
                                }}
                            />
                        ) : (
                            <Avatar
                                sx={{
                                    backgroundColor: '#1976D2',
                                    width: '100%',
                                    height: '100%',
                                    fontSize: size * 0.35,
                                    transition: 'filter 0.3s ease-in-out',
                                    filter: showCameraIcon ? 'brightness(0.4)' : 'brightness(1)',
                                }}
                            >
                                ?
                            </Avatar>
                        )}

                        <img
                            alt="upload-icon"
                            src={`${process.env.PUBLIC_URL}/assets/icons/navigation/cam.svg`}
                            className="upload-icon"
                            style={{
                                position: 'absolute',
                                width: `${size * 0.45}px`,
                                height: `${size * 0.45}px`,
                                filter: 'invert(100%)',
                                opacity: showCameraIcon ? 1 : 0,
                                transition: 'opacity 0.3s ease-in-out',
                                pointerEvents: 'none',
                                zIndex: 1,
                            }}
                        />
                    </div>
                </IconButton>
            </label>
        </div>
    );
};

export const UploadLogoHeader = ({ id, inputId = 'upload-logo-header' }) => {
    const { getLogoUrlForOrganization, setLogoUrlForOrganization } = useLogo();
    const { config } = UseAppConfig();
    const [loading, setLoading] = useState(false);
    const logoUrl = getLogoUrlForOrganization(id);

    const handleFileUpload = async (event) => {
        const file = event.target.files[0];
        if (!file) return;

        console.log('Archivo cargado:', file);

        const formData = new FormData();
        formData.append('perfilImagen', file);
        formData.append('id', id);

        setLoading(true);

        try {
            const response = await sendImage(
                `${config.API_BASE_URL}/actualizarImagen/organizaciones`,
                formData
            );

            console.log('Respuesta de la API:', response);

            if (response.success) {
                const updatedLogoResponse = await sendPostData(
                    `${config.API_BASE_URL}/imagen/organizaciones`,
                    { id }
                );

                if (updatedLogoResponse.success) {
                    const imageName = updatedLogoResponse.imageName; 
                    const imageUrl = `${config.API_BASE_URL}/images/organizaciones/${imageName}`;
                    console.log('URL actualizada:', imageUrl);
                    setLogoUrlForOrganization(id, imageUrl);
                } else {
                    console.error('Error al obtener el nombre de la imagen:', updatedLogoResponse.message);
                }
            } else {
                alert('Error al cargar la imagen');
            }
        } catch (error) {
            alert('Error en la conexión');
            console.error('Error de conexión:', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Box display="flex" alignItems="center">
            <label htmlFor={inputId} style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                <input
                    id={inputId}
                    type="file"
                    accept="image/*"
                    style={{ display: 'none' }}
                    onChange={handleFileUpload}
                />
                <IconButton component="span">
                    {logoUrl ? (
                        <img
                            alt="logo"
                            src={logoUrl}
                            style={{
                                maxWidth: '100px',
                                maxHeight: '50px',
                                objectFit: 'contain',
                            }}
                        />
                    ) : (
                        <>
                            <img
                                alt="upload-icon"
                                src={`${process.env.PUBLIC_URL}/assets/icons/navigation/upload-ic.svg`}
                                width="20px"
                                height="20px"
                            />
                            <Typography
                                variant="body1"
                                sx={{
                                    color: '#1976D2',
                                    marginLeft: '8px',
                                    fontWeight: '500',
                                }}
                            >
                                Carga tu logotipo
                            </Typography>
                        </>
                    )}
                </IconButton>
            </label>
        </Box>
    );
};

export const UploadLogoSettings = ({ id, inputId = 'upload-logo-settings' }) => {
    const { getLogoUrlForOrganization, setLogoUrlForOrganization } = useLogo();
    const { config } = UseAppConfig();
    const [loading, setLoading] = useState(false);
    const logoUrl = getLogoUrlForOrganization(id);

    const handleFileUpload = async (event) => {
        const file = event.target.files[0];
        if (!file) return;

        console.log('Archivo cargado:', file);

        const formData = new FormData();
        formData.append('perfilImagen', file);
        formData.append('id', id);

        setLoading(true);

        try {
            const response = await sendImage(
                `${config.API_BASE_URL}/actualizarImagen/organizaciones`,
                formData
            );

            console.log('Respuesta de la API:', response);

            if (response.success) {
                const updatedLogoResponse = await sendPostData(
                    `${config.API_BASE_URL}/imagen/organizaciones`,
                    { id }
                );

                if (updatedLogoResponse.success) {
                    const imageName = updatedLogoResponse.imageName;
                    const imageUrl = `${config.API_BASE_URL}/images/organizaciones/${imageName}`; 
                    console.log('URL actualizada:', imageUrl);
                    setLogoUrlForOrganization(id, imageUrl);
                } else {
                    console.error('Error al obtener el nombre de la imagen:', updatedLogoResponse.message);
                }
            } else {
                alert('Error al cargar la imagen');
            }
        } catch (error) {
            alert('Error en la conexión');
            console.error('Error de conexión:', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 3 }}>
            <Box
                sx={{
                    width: 90,
                    height: 90,
                    border: '1px solid #ccc',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: 3, 
                    backgroundColor: '#f9f9f9',
                    overflow: 'hidden', 
                    marginBottom: 3,
                }}
            >
                {logoUrl ? (
                    <img
                        src={logoUrl}
                        alt="Logo de la organización"
                        style={{
                            maxWidth: '100%',
                            maxHeight: '100%',
                            objectFit: 'contain',
                        }}
                    />
                ) : (
                    'Sin logo'
                )}
            </Box>
            <Box>
                <input
                    id={inputId}
                    type="file"
                    accept="image/*"
                    style={{ display: 'none' }}
                    onChange={handleFileUpload}
                />
                <label htmlFor={inputId}>
                    <Button
                        variant="outlined"
                        component="span"
                        sx={{
                            borderRadius: '8px',
                            textTransform: 'none',
                            height: 40,
                            marginBottom: 3,
                            transition: 'all 0.3s ease-in-out',
                            borderColor: '#1976D2',
                            color: '#1976D2',
                            '&:hover': {
                                backgroundColor: '#1976D2',
                                color: '#fff',
                            },
                        }}
                        disabled={loading}
                    >
                        {loading ? <CircularProgress size={24} /> : 'Cambiar logo'}
                    </Button>
                </label>
            </Box>
        </Box>
    );
};


