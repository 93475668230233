import { profileFields } from "../../data/forms/profile";
import * as yup from "yup";
import { UseAppConfig } from "../../system/config";
import { getPostData } from "../../system/getData";
import { useEffect, useState } from "react";
import { GeneraFormularioAjustes } from "../globals/GlobalFormsXS";

const SettingsXS = () => {
    const { config } = UseAppConfig();
    const [userData, setUserData] = useState(null);
    const [organizationData, setOrganizationData] = useState(null); 

    const dataConfig = {
        titulo: "Ajustes",
        subtitulo: "Perfil",
        tituloPsw: "Cambiar contraseña",
        userFields: profileFields.userFields, 
        orgFields: profileFields.organizationFields, 
        passwordFields: profileFields.passwordFields,
    };

    const validateSchemas = {
        usuario: yup.object().shape({
            NombreUsuario: yup
                .string()
                .matches(/^[a-zA-ZáéíóúÁÉÍÓÚñÑ\s]+$/, "El nombre tiene que ser alfanumérico")
                .required("El nombre es requerido"),
            ApellidosUsuario: yup
                .string()
                .matches(/^[a-zA-ZáéíóúÁÉÍÓÚñÑ\s]+$/, "Los apellidos tienen que ser alfanumérico")
                .required("Los apellidos son requeridos"),
            NombreOrganizacion: yup
                .string()
                .required("El nombre de la organización es requerido"),
            CorreoUsuario: yup
                .string()
                .email("Correo electrónico inválido")
                .required("El correo es requerido"),
            TelefonoUsuario: yup
                .string()
                .matches(/^\d{10}$/, "El teléfono debe contener exactamente 10 dígitos")
                .test(
                    "solo-numeros",
                    "El teléfono solo debe contener números",
                    value => /^[0-9]+$/.test(value)
                )
                .notRequired(),
        }),
        organizacion: yup.object().shape({
            NombreOrganizacion: yup
                .string()
                .matches(/^[a-zA-ZáéíóúÁÉÍÓÚñÑ\s]+$/, "El nombre tiene que ser alfanumérico")
                .required("El nombre de la organización es requerido"),
            GiroOrganizacion: yup
                .string()
                .required("El giro es requerido"),
            CorreoOrganizacion: yup
                .string()
                .email("Correo electrónico inválido")
                .required("El correo es requerido"),
            TelefonoOrganizacion: yup
                .string()
                .matches(/^\d{10}$/, "El teléfono debe contener exactamente 10 dígitos")
                .test(
                    "solo-numeros",
                    "El teléfono solo debe contener números",
                    value => /^[0-9]+$/.test(value)
                )
                .notRequired(),
            FactorUtilidadOrganizacion: yup
                .number()
                .required("El factor de utilidad es requerido")
                .min(0, "El factor de utilidad debe ser positivo")
                .test(
                    "es-numero-con-dos-decimales",
                    "Solo se permiten dos decimales",
                    value => /^\d+(\.\d{1,2})?$/.test(value)
                )
                .typeError("El factor de utilidad debe ser un número válido"),

        }),
        cambioPassword: yup.object().shape({
            currentPassword: yup
                .string()
                .min(8, "La contraseña debe tener al menos 8 caracteres")
                .required("La contraseña actual es requerida"),
                newPassword: yup
                .string()
                .min(8, "La nueva contraseña debe tener al menos 8 caracteres")
                .matches(/[A-Z]/, "La nueva contraseña debe contener al menos una letra mayúscula")
                .matches(/\d/, "La nueva contraseña debe contener al menos un número")
                .matches(/[\W_]/, "La nueva contraseña debe contener al menos un carácter especial")
                .required("La nueva contraseña es requerida"),
            
            confirmPassword: yup
                .string()
                .oneOf([yup.ref('newPassword'), null], "Las contraseñas deben coincidir")
                .required("Confirma tu contraseña"),
        }),
    };

    useEffect(() => {
        const fetchUserProfile = async () => {
            try {
                const sessionData = JSON.parse(sessionStorage.getItem("userData"));
                const correo = sessionData?.correo || "";
                const tipoUsuID = sessionData?.tipoUsuID;

                const response = await getPostData(
                    `${config.API_BASE_URL}/perfilUsuario`,
                    { correo, tipoUsuID }
                );

                if (response && response.length > 0) {
                    const data = response[0]; 

                    const userData = {
                        UsuarioID: data.UsuarioID,
                        NombreUsuario: data.NombreUsuario,
                        ApellidosUsuario: data.ApellidosUsuario,
                        CorreoUsuario: data.CorreoUsuario,
                        TelefonoUsuario: data.TelefonoUsuario,
                        EstadoUsuario: data.EstadoUsuario,
                        TipoUsuarioID: data.TipoUsuarioID,
                        OrganizacionID: data.OrganizacionID,
                        PerfilImagenUsuario: data.PerfilImagenUsuario,
                        FechaCreacionUsuario: data.FechaCreacionUsuario,
                        NombreOrganizacion: data.NombreOrganizacion,

                    };

                    let organizationData = null;
                    if (tipoUsuID === 1 || tipoUsuID === 2) {
                        organizationData = {
                            NombreOrganizacion: data.NombreOrganizacion,
                            FactorUtilidadOrganizacion: data.FactorUtilidadOrganizacion,
                            CorreoOrganizacion: data.CorreoOrganizacion,
                            GiroOrganizacion: data.GiroOrganizacion,
                            TelefonoOrganizacion: data.TelefonoOrganizacion,
                            PerfilImagenOrganizacion: data.PerfilImagenOrganizacion,
                            FechaCreacionOrganizacion: data.FechaCreacionOrganizacion,
                        };
                    } else if (tipoUsuID === 3) {
                        organizationData = {
                            NombreOrganizacion: data.NombreOrganizacion || "", 
                        };
                    }
                    setUserData(userData);
                    setOrganizationData(organizationData);

                    console.log("Datos del usuario:", userData);
                    console.log("Datos de la organización:", organizationData);
                }
            } catch (error) {
                console.error("Error al obtener el perfil del usuario:", error);
            }
        };

        fetchUserProfile();
    }, [config]);


    if (!userData || !organizationData) {
        return <div></div>; 
    }

    return (
        <GeneraFormularioAjustes
            userData={userData}
            setUserData={setUserData}
            organizationData={organizationData}
            setOrganizationData={setOrganizationData}
            dataConfig={dataConfig}
            validateSchemas={validateSchemas}
        />
    );
};

export default SettingsXS;
