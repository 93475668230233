import React from "react";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";

const StyledSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase': {
        color: '#fff',
        marginLeft: '-2px',
        '&.Mui-checked': {
            color: '#fff',
            transform: 'translateX(16px)',
            '& + .MuiSwitch-track': {
                backgroundColor: '#249F7A',
                opacity: 1,
            },
        },
    },
    '& .MuiSwitch-thumb': {
        width: 14,
        height: 14,
        margin: 6.5,
    },
    '& .MuiSwitch-track': {
        backgroundColor: '#7a7a7a',
        opacity: 1,
        borderRadius: 26 / 2,
        height: 20,
    },
}));

const CustomSwitch = ({ id, filtro, checked = false, onChange, ...props }) => {
    const handleSwitchChange = (event) => {
        const nuevoStatus = event.target.checked ? 1 : 0;
        if (typeof onChange === "function") {
            onChange(id, filtro, nuevoStatus); 
        } else {
            console.error("La función 'onChange' no está definida.");
        }
    };

    return (
        <StyledSwitch
            checked={checked}
            onChange={handleSwitchChange}
            {...props}
        />
    );
};

export default CustomSwitch;
