import React, { useState } from "react";
import { Modal, Box, Typography, Button } from "@mui/material";
import { getPostData } from "../../system/getData";
import { useSnackbar } from "notistack";

const DeleteModal = ({ open, onClose, title, description, successMessage, errorMessage, endpoint, dataId, apiBaseUrl, confirmLabel = "Eliminar", cancelLabel = "Cancelar", confirmColor = "#EC3931", cancelColor = "#343D48", onSuccess }) => {
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(false);

    const handleDelete = async () => {
        try {
            setLoading(true);

            if (!endpoint || !dataId) {
                throw new Error("Endpoint o ID no definidos.");
            }
            const completeEndpoint = endpoint.startsWith(apiBaseUrl)
                ? endpoint
                : `${apiBaseUrl}${endpoint}`;
            console.log("Endpoint configurado correctamente:", completeEndpoint);
            const response = await getPostData(completeEndpoint, { id: dataId });

            if (response.message) {
                enqueueSnackbar(successMessage || "Eliminado correctamente.", {
                    variant: "success",
                });

                if (onSuccess) {
                    onSuccess();
                }
                onClose();
            } else {
                throw new Error(response.error || "Error desconocido");
            }
        } catch (error) {
            console.error("Error al eliminar:", error);
            enqueueSnackbar(errorMessage || "Error al eliminar.", {
                variant: "error",
            });
        } finally {
            setLoading(false);
        }
    };
    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="delete-modal-title"
            aria-describedby="delete-modal-description"
        >
            <Box
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: 450,
                    bgcolor: "background.paper",
                    boxShadow: 24,
                    p: 5,
                    borderRadius: 2,
                    textAlign: "center",
                }}
            >
                <Typography
                    id="delete-modal-title"
                    variant="h5"
                    sx={{
                        fontWeight: "bold",
                        fontSize: "20px",
                        marginBottom: 2,
                        paddingX: 0,
                        width: "100%",
                    }}
                >
                    {title}
                </Typography>
                <Typography
                    id="delete-modal-description"
                    sx={{
                        marginBottom: 4,
                        fontSize: "14px",
                        paddingX: 0,
                    }}
                >
                    {description}
                </Typography>

                <Box display="flex" justifyContent="center" gap="20px">
                    <Button
                        variant="contained"
                        onClick={onClose}
                        sx={{
                            backgroundColor: cancelColor,
                            color: "#fff",
                            textTransform: "none",
                            borderRadius: "8px",
                            fontSize: "13px",
                            padding: "10px 25px",
                            "&:hover": { backgroundColor: "#2C3540" },
                        }}
                        disabled={loading}
                    >
                        {cancelLabel}
                    </Button>
                    <Button
                        variant="contained"
                        onClick={handleDelete}
                        sx={{
                            backgroundColor: confirmColor,
                            color: "#fff",
                            textTransform: "none",
                            fontSize: "13px",
                            borderRadius: "8px",
                            padding: "10px 30px",
                            "&:hover": { backgroundColor: "#b71c1c" },
                        }}
                        disabled={loading}
                    >
                        <Box
                            component="img"
                            src={`${process.env.PUBLIC_URL}/assets/icons/navigation/eliminar.svg`}
                            alt="Eliminar"
                            sx={{
                                width: "20px",
                                height: "20px",
                                marginRight: "8px",
                                filter: "brightness(0) invert(1)",
                            }}
                        />
                        {loading ? "Eliminando..." : confirmLabel}
                    </Button>
                </Box>
            </Box>
        </Modal>

    );

};

export default DeleteModal;

