// users.js
export const usersFields = [
    {
        name: "nombre",
        label: "Nombre",
        gridColumn: "span 1",
        formType: "text",
    },
    {
        name: "apellidos", 
        label: "Apellido(s)",
        gridColumn: "span 1",
        formType: "text",
    },
    {
        name: "correo",
        label: "Correo Electrónico",
        gridColumn: "span 1",
        formType: "email",
    },
    {
        name: "telefono", 
        label: "",
        gridColumn: "span 1",
        formType: "tel",
    },
    {
        name: "organizacionID", 
        label: "Organización",
        gridColumn: "span 1",
        formType: "select",
        catalog: "organizaciones",
    },
    {
        name: "tipoUsuID", 
        label: "Tipo de Usuario",
        gridColumn: "span 1",
        formType: "select",
        catalog: "usuarios",
    },
];
