import React, { useEffect, useState, useCallback } from "react";
import { SimpleDataTable } from "../../components/globals/DataTableXS";
import { formConfig } from "../../components/globals/useFormConfig";
import { UseAppConfig } from "../../system/config";
import { Box, Typography, Chip } from "@mui/material";

const CategoryDetails = ({ dataConfig }) => {
    const initValues = dataConfig?.initValues;

    if (!initValues?.id) return null;

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                padding: "16px",
                gap: "14px",
                width: "100%"
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                }}
            >
                <Typography
                    variant="h3"
                    sx={{
                        fontWeight: "bold",
                    }}
                >
                    {initValues?.nombre || "Nombre no disponible"}
                </Typography>
                <Chip
                    label={`${initValues?.numeroArticulos || 0} arts.`}
                    size="small"
                    sx={{
                        backgroundColor: "#e0e0e0",
                        color: "#333",
                        fontSize: "10px",
                        fontWeight: "small",
                    }}
                />
            </Box>
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                }}
            >
                <img
                    alt="factor-utilidad-icon"
                    src={`${process.env.PUBLIC_URL}/assets/icons/navigation/graph-utilidad.svg`}
                    width="18px"
                    height="18px"
                />
                <Typography
                    variant="body2"
                    sx={{
                        color: "#1976d2",
                        fontSize: "13px",
                    }}
                >
                    Factor de utilidad: {initValues?.factorUtilidad ? `${initValues.factorUtilidad}%` : "No disponible"}
                </Typography>
            </Box>
            <Box
                sx={{
                    position: "relative",
                    width: "calc(100% + 32px)",
                    marginLeft: "-16px",
                    marginTop: "10px",
                    marginBottom: "-10px",
                    borderBottom: "2px solid #ddd",
                }}
            />
        </Box>
    );
};

const Categories = () => {
    const { config } = UseAppConfig();
    const [configData, setConfigData] = useState(null);
    const [columns, setColumns] = useState([]);

    const generateColumns = useCallback(async (tableHeaders) => {
        const userData = JSON.parse(sessionStorage.getItem("userData"));
        const tipoUsuID = userData?.tipoUsuID;

        const updatedColumns = tableHeaders
            .filter((header) => {
                if (header.field === "organizacionNombre") {
                    return tipoUsuID === 1;
                }
                return true;
            })
            .map((header) => {
                if (header.field === "factorUtilidad") {
                    return {
                        ...header,
                        renderCell: (params) => `${params.value}%`,
                    };
                }
                return header;
            });

        setColumns(updatedColumns);
    }, []);


    const fetchFormConfig = useCallback(
        async (type = "initialCategory", data = null) => {
            try {
                const rawConfigData = await formConfig(type, data);

                const endpoint = rawConfigData.dataConfig?.getData || rawConfigData.endpoint;

                if (!endpoint) {
                    throw new Error(
                        `No se encontró un endpoint válido ni 'getData' en los datos de configuración para el tipo: ${type}`
                    );
                }

                const fullEndpoint = `${config.API_BASE_URL}${endpoint}`;

                const fullConfigData = {
                    ...rawConfigData,
                    dataConfig: {
                        ...rawConfigData.dataConfig,
                        endpoint: fullEndpoint,
                    },
                };

                if (type === "initialCategory") {
                    await generateColumns(fullConfigData.dataConfig.tableHeader || []);
                    setConfigData(fullConfigData);
                }

                return fullConfigData;
            } catch (error) {
                console.error("Error obteniendo formConfig:", error);
                throw error;
            }
        },
        [config, generateColumns]
    );

    useEffect(() => {
        fetchFormConfig();
    }, [fetchFormConfig]);

    return (
        <SimpleDataTable
            formConfig={{
                ...configData,
                dataConfig: {
                    ...configData?.dataConfig,
                    tableHeader: columns,
                },
            }}
            fetchFormConfig={fetchFormConfig}
            detailsComponent={CategoryDetails}
        />
    );
};

export default Categories;
