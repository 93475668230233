import { Box } from "@mui/material";

export const tableHeaders = [
    {
        field: "nombre",
        headerName: "Nombre",
        headerAlign: "left",
        align: "left",
        flex: 1,
        minWidth: 150,
    },
    {
        field: "descripcion",
        headerName: "Descripción",
        headerAlign: "left",
        align: "left",
        flex: 2,
        minWidth: 300,
    },
    {
        field: "numeroArticulos",
        headerName: "Artículos",
        headerAlign: "left",
        align: "left",
        width: 100,
        renderCell: (params) => params.value || 0, 
    },
    {
        field: "factorUtilidad",
        headerName: "Factor de Utilidad",
        headerAlign: "left",
        align: "left",
        width: 150,
        renderCell: (params) => `${params.value?.toFixed(2) || "0.00"}`, 
    },
    {
        field: "status",
        headerName: "Activo",
        headerAlign: "center",
        align: "center",
        width: 80,
        isSwitch: true,
    },
    {
        field: "acciones",
        headerName: "",
        headerAlign: "center",
        align: "center",
        width: 100,
        renderCell: () => (
            <Box display="flex" gap="15px">
                <img
                    src={`${process.env.PUBLIC_URL}/assets/icons/navigation/eliminar.svg`}
                    alt="Eliminar"
                    width="25"
                    height="25"
                    style={{ cursor: "pointer" }}
                />
                <img
                    src={`${process.env.PUBLIC_URL}/assets/icons/navigation/editar.svg`}
                    alt="Editar"
                    width="25"
                    height="25"
                    style={{ cursor: "pointer" }}
                />
            </Box>
        ),
    },
];
