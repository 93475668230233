import React, { useState } from "react";
import { Box, Button, Typography, Modal, TextField, Avatar, Chip, Grid } from "@mui/material";
import { Formik, Form, Field } from "formik";
import ComboBoxFilled from "../globals/ComboBoxFilled";
import { useSnackbar } from "notistack";
import { UseAppConfig } from "../../system/config";
import { getPostData } from "../../system/getData";
import { UploadLogoSettings, UploadProfile } from "../globals/UploadImages";

export const GenerarFormularioSimpleXS = ({ open, handleClose, title, initValues, formConfig, detailsComponent, validateSchemas, onSubmit, refreshTable }) => {
    const { enqueueSnackbar } = useSnackbar();
    const [isSubmitting, setIsSubmitting] = useState(false);
    return (
        <>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: 500,
                        bgcolor: "background.paper",
                        boxShadow: 24,
                        borderRadius: 2,
                    }}
                >
                    {detailsComponent && (
                        <Box sx={{ marginBottom: "0px" }}>
                            {React.createElement(detailsComponent, { dataConfig: { initValues } })}
                        </Box>
                    )}

                    <Box sx={{ padding: "16px" }}>
                        <Typography
                            id="modal-title"
                            variant="h4"
                            component="h2"
                            sx={{
                                fontWeight: "bold",
                                marginBottom: "25px",
                            }}
                        >
                            {title || formConfig?.title || "Formulario"}
                        </Typography>
                        <Formik
                            initialValues={initValues}
                            validationSchema={validateSchemas}
                            onSubmit={async (values) => {
                                setIsSubmitting(true);
                                try {
                                    await onSubmit(values);
                                    if (refreshTable) {
                                        await refreshTable();
                                    }
                                    handleClose();
                                } catch (error) {
                                    console.error("Error en el submit:", error);
                                    enqueueSnackbar(formConfig?.errorMessage || "Error al guardar los datos", {
                                        variant: "error",
                                        autoHideDuration: 3000,
                                    });
                                } finally {
                                    setIsSubmitting(false);
                                }
                            }}

                        >
                            {({ errors, touched, handleChange, values, setFieldValue, submitForm, validateForm, setFieldTouched }) => (
                                <Form>
                                    <Box
                                        sx={{
                                            display: "grid",
                                            gap: 2,
                                            gridTemplateColumns: formConfig?.gridTemplateColumns || "1fr 1fr",
                                        }}
                                    >
                                        {formConfig?.fields.map((field) => (
                                            <Box
                                                key={field.name}
                                                sx={{
                                                    gridColumn: field.gridColumn || "span 1",
                                                }}
                                            >
                                                <Typography
                                                    sx={{
                                                        fontSize: "0.875rem",
                                                        fontWeight: "bold",
                                                        marginBottom: "4px",
                                                    }}
                                                >
                                                    {field.label}
                                                </Typography>
                                                {field.name === "factorUtilidad" ? (
                                                    <Field
                                                        name={field.name}
                                                        as={TextField}
                                                        variant="standard"
                                                        fullWidth
                                                        placeholder="Ingresar porcentaje de utilidad"
                                                        InputProps={{
                                                            endAdornment: (
                                                                <Box
                                                                    component="span"
                                                                    sx={{
                                                                        fontSize: "0.75rem",
                                                                        marginLeft: "-8px",
                                                                    }}
                                                                >
                                                                    %
                                                                </Box>
                                                            ),
                                                        }}
                                                        sx={{
                                                            "& .MuiInputBase-root": {
                                                                borderRadius: "8px",
                                                                border: "1px solid #ccc",
                                                                padding: "8px 12px",
                                                                height: "48px",
                                                            },
                                                            "& .MuiInput-underline:before, & .MuiInput-underline:after": {
                                                                display: "none",
                                                            },
                                                        }}
                                                        onChange={handleChange}
                                                        error={touched[field.name] && Boolean(errors[field.name])}
                                                        helperText={touched[field.name] && errors[field.name]}
                                                    />
                                                ) : field.name === "descripcion" && field.formType === "textarea" ? (
                                                    <Box>
                                                        {/* Título con "(opcional)" */}
                                                        <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
                                                            <Typography
                                                                sx={{
                                                                    fontSize: "0.875rem",
                                                                    fontWeight: "bold",
                                                                    color: "#333",
                                                                }}
                                                            >
                                                                Descripción
                                                            </Typography>
                                                            <Typography
                                                                sx={{
                                                                    fontSize: "0.875rem",
                                                                    color: "#666", // Color gris para "(opcional)"
                                                                }}
                                                            >
                                                                (Opcional)
                                                            </Typography>
                                                        </Box>
                                                        {/* Campo de entrada */}
                                                        <Field
                                                            name={field.name}
                                                            as={TextField}
                                                            variant="standard"
                                                            fullWidth
                                                            multiline
                                                            rows={4} // Incrementa el tamaño del área de texto
                                                            placeholder="Agrega descripción..."
                                                            sx={{
                                                                "& .MuiInputBase-root": {
                                                                    borderRadius: "8px",
                                                                    border: "1px solid #ccc",
                                                                    padding: "12px", // Espaciado interno ajustado
                                                                },
                                                                "& .MuiInput-underline:before, & .MuiInput-underline:after": {
                                                                    display: "none",
                                                                },
                                                            }}
                                                            onChange={handleChange}
                                                            error={touched[field.name] && Boolean(errors[field.name])}
                                                            helperText={touched[field.name] && errors[field.name]}
                                                        />
                                                    </Box>
                                                ) : field.formType === "select" ? (
                                                    <ComboBoxFilled
                                                        data={field}
                                                        value={values[field.name] || ""}
                                                        onChange={(e) => setFieldValue(field.name, parseInt(e.target.value, 10))}
                                                        error={touched[field.name] && Boolean(errors[field.name])}
                                                        helperText={touched[field.name] && errors[field.name]}
                                                        placeholder={formConfig?.placeholderCB || "Seleccionar..."}
                                                    />

                                                ) : field.name === "telefono" ? (
                                                    <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                                                        {/* Título del campo con texto "(opcional)" al lado */}
                                                        <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
                                                            <Typography
                                                                sx={{
                                                                    marginBottom: "-10px",
                                                                    fontSize: "0.875rem",
                                                                    fontWeight: "bold",
                                                                    color: "#333",
                                                                }}
                                                            >
                                                                Teléfono
                                                            </Typography>
                                                            <Typography
                                                                sx={{
                                                                    fontSize: "0.875rem",
                                                                    color: "#666",
                                                                    marginBottom: "-5px", 
                                                                }}
                                                            >
                                                                (Opcional)
                                                            </Typography>
                                                        </Box>
                                                        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                                                            <Box
                                                                sx={{
                                                                    width: "100px",
                                                                    height: "48px",
                                                                    border: "1px solid #ccc",
                                                                    borderRadius: "8px",
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    justifyContent: "center",
                                                                    backgroundColor: "#f9f9f9",
                                                                    gap: "8px",
                                                                }}
                                                            >
                                                                <img
                                                                    src={`${process.env.PUBLIC_URL}/assets/icons/navigation/flag-mx.png`}
                                                                    alt="Bandera México"
                                                                    style={{
                                                                        width: "28px",
                                                                        height: "20px",
                                                                        borderRadius: "2px",
                                                                    }}
                                                                />
                                                                <img
                                                                    src={`${process.env.PUBLIC_URL}/assets/icons/navigation/arrow-drop.svg`}
                                                                    alt="Arrow Drop"
                                                                    style={{
                                                                        width: "15px",
                                                                        height: "15px",
                                                                        borderRadius: "2px",
                                                                    }}
                                                                />
                                                            </Box>
                                                            <Field
                                                                name="telefono"
                                                                as={TextField}
                                                                variant="standard"
                                                                fullWidth
                                                                InputProps={{
                                                                    startAdornment: (
                                                                        <Typography
                                                                            sx={{
                                                                                fontSize: "0.875rem",
                                                                                color: "#666",
                                                                                marginRight: "12px",
                                                                            }}
                                                                        >
                                                                            +52
                                                                        </Typography>
                                                                    ),
                                                                }}
                                                                sx={{
                                                                    "& .MuiInputBase-root": {
                                                                        borderRadius: "8px",
                                                                        border: "1px solid #ccc",
                                                                        padding: "8px 12px",
                                                                        height: "48px",
                                                                    },
                                                                    "& .MuiInput-underline:before, & .MuiInput-underline:after": {
                                                                        display: "none",
                                                                    },
                                                                }}
                                                                onChange={handleChange}
                                                                error={touched[field.name] && Boolean(errors[field.name])}
                                                                helperText={touched[field.name] && errors[field.name]}
                                                            />
                                                        </Box>
                                                    </Box>

                                                ) : (
                                                    <Field
                                                        name={field.name}
                                                        as={TextField}
                                                        variant="standard"
                                                        fullWidth
                                                        placeholder={field.placeholder || ""}
                                                        sx={{
                                                            "& .MuiInputBase-root": {
                                                                borderRadius: "8px",
                                                                border: "1px solid #ccc",
                                                                padding: "8px 12px",
                                                                height: "48px",
                                                            },
                                                            "& .MuiInput-underline:before, & .MuiInput-underline:after": {
                                                                display: "none",
                                                            },
                                                        }}
                                                        onChange={handleChange}
                                                        error={touched[field.name] && Boolean(errors[field.name])}
                                                        helperText={touched[field.name] && errors[field.name]}
                                                    />
                                                )}
                                            </Box>
                                        ))}
                                    </Box>
                                    <Box
                                        sx={{
                                            position: "relative",
                                            width: "calc(100% + 32px)",
                                            marginLeft: "-16px",
                                            marginTop: "15px",
                                            marginBottom: "15px",
                                            borderBottom: "2px solid #ddd",
                                        }}
                                    />
                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            gap: 2,
                                            alignItems: "center",
                                        }}
                                    >
                                        {initValues?.id ? (
                                            <Button
                                                variant="outlined"
                                                disabled={isSubmitting}
                                                onClick={() =>
                                                    formConfig.handleOpenConfirmModal("delete", formConfig.actionModule, initValues, handleClose)
                                                }
                                                sx={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    gap: "8px",
                                                    color: "#f44336",
                                                    backgroundColor: "#fdecea",
                                                    borderColor: "#f44336",
                                                    fontSize: "13px",
                                                    padding: "10px 20px",
                                                    borderRadius: "8px",
                                                    textTransform: "none",
                                                    "&:hover": {
                                                        backgroundColor: "#fddede",
                                                        borderColor: "#d32f2f",
                                                    },
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        width: "25px",
                                                        height: "25px",
                                                        textTransform: "none",
                                                        WebkitMaskImage: `url(${process.env.PUBLIC_URL}/assets/icons/navigation/eliminar.svg)`,
                                                        WebkitMaskRepeat: "no-repeat",
                                                        backgroundColor: "#f44336",

                                                    }}
                                                />
                                                Eliminar
                                            </Button>
                                        ) : (
                                            <Box sx={{ width: "118px" }} />
                                        )}
                                        <Box sx={{ display: "flex", gap: 2 }} >
                                            <Button
                                                variant="contained"
                                                disabled={isSubmitting}
                                                onClick={handleClose}
                                                sx={{
                                                    backgroundColor: "#343D48",
                                                    color: "#fff",
                                                    fontSize: "13px",
                                                    padding: "10px 25px",
                                                    textTransform: "none",
                                                    borderRadius: "8px",
                                                    "&:hover": { backgroundColor: "#2C3540" },
                                                }}
                                            >
                                                Cancelar
                                            </Button>
                                            <Button
                                                type="button"
                                                disabled={isSubmitting}
                                                onClick={async () => {
                                                    const errors = await validateForm();
                                                    if (Object.keys(errors).length > 0) {
                                                        Object.keys(errors).forEach((field) => {
                                                            setFieldTouched(field, true);
                                                        });
                                                    } else {
                                                        submitForm();
                                                    }
                                                }}
                                                variant="contained"
                                                sx={{
                                                    backgroundColor: "#1976d2",
                                                    color: "#fff",
                                                    fontSize: "13px",
                                                    padding: "10px 30px",
                                                    borderRadius: "8px",
                                                    gap: "5px",
                                                    textTransform: "none",
                                                    "&:hover": { backgroundColor: "#1565c0" },
                                                }}
                                            >
                                                Guardar
                                            </Button>
                                        </Box>
                                    </Box>
                                </Form>
                            )}
                        </Formik>
                    </Box>
                </Box>
            </Modal>
        </>
    );
};

export const GeneraFormularioAjustes = ({ userData, setUserData, organizationData, setOrganizationData, dataConfig, validateSchemas }) => {
    const { config } = UseAppConfig();
    const { enqueueSnackbar } = useSnackbar();
    const { NombreUsuario, ApellidosUsuario, PerfilImagenUsuario, TipoUsuarioID, FechaCreacionUsuario } = userData || {};
    const [userDirty, setUserDirty] = useState(false);
    const [organizationDirty, setOrganizationDirty] = useState(false);
    const [showPasswordChange, setShowPasswordChange] = useState(false);
    const [showModal, setShowModal] = useState(false);

    const getRoleLabel = (tipoUsuID) => {
        switch (tipoUsuID) {
            case 1:
                return "Admin";
            case 2:
                return "Gerente";
            case 3:
                return "Colaborador";
            default:
                return "Desconocido";
        }
    };

    const initialUserValues = {
        ...userData,
    };

    const initialOrgValues = {
        ...organizationData,
    };

    const handleSubmit = async (values, actionType) => {
        try {
            if (actionType === "usuario") {
                const payloadUsuario = {
                    id: userData.UsuarioID,
                    nombre: values.NombreUsuario || userData.NombreUsuario,
                    apellidos: values.ApellidosUsuario || userData.ApellidosUsuario,
                    correo: values.CorreoUsuario || userData.CorreoUsuario,
                    telefono: values.TelefonoUsuario || userData.TelefonoUsuario,
                    organizacionID: userData.OrganizacionID,
                    tipoUsuID: userData.TipoUsuarioID,
                };

                const response = await getPostData(`${config.API_BASE_URL}/editar/usuario`, payloadUsuario);
                if (response) {
                    setUserData((prevUserData) => ({
                        ...prevUserData,
                        NombreUsuario: payloadUsuario.nombre,
                        ApellidosUsuario: payloadUsuario.apellidos,
                        CorreoUsuario: payloadUsuario.correo,
                        TelefonoUsuario: payloadUsuario.telefono,
                    }));

                    enqueueSnackbar("Actualizaste tu perfil correctamente.", {
                        variant: "success",
                    });
                }
            } else if (actionType === "organizacion") {
                const payloadOrganizacion = {
                    id: userData.OrganizacionID,
                    nombre: values.NombreOrganizacion || userData.NombreOrganizacion,
                    correo: values.CorreoOrganizacion || userData.CorreoOrganizacion,
                    telefono: values.TelefonoOrganizacion || userData.TelefonoOrganizacion,
                    giro: values.GiroOrganizacion || userData.GiroOrganizacion,
                    factorUtilidad: values.FactorUtilidadOrganizacion || userData.FactorUtilidadOrganizacion,
                };

                const response = await getPostData(`${config.API_BASE_URL}/editar/organizacion`, payloadOrganizacion);
                if (response) {
                    setOrganizationData((prevOrganizationData) => ({
                        ...prevOrganizationData,
                        NombreOrganizacion: payloadOrganizacion.nombre,
                        CorreoOrganizacion: payloadOrganizacion.correo,
                        TelefonoOrganizacion: payloadOrganizacion.telefono,
                        GiroOrganizacion: payloadOrganizacion.giro,
                        FactorUtilidadOrganizacion: payloadOrganizacion.factorUtilidad,
                    }));

                    enqueueSnackbar("Organización actualizada correctamente.", {
                        variant: "success",
                    });
                }
            } else if (actionType === "password") {
                const { currentPassword, newPassword, confirmPassword } = values;
                const payloadPassword = {
                    id: userData.UsuarioID,
                    currentPassword,
                    newPassword,
                    confirmPassword,
                };

                try {
                    const response = await getPostData(`${config.API_BASE_URL}/nuevaPassword`, payloadPassword);

                    if (response && response.message === 'Contraseña cambiada exitosamente') {
                        setShowModal(true);
                    } else {

                        enqueueSnackbar(response.error || "Ocurrió un error al cambiar la contraseña.", { variant: "error" });
                    }
                } catch (error) {
                    if (error.response && error.response.data && error.response.data.error) {
                        enqueueSnackbar(error.response.data.error, { variant: "error" });
                    } else {
                        enqueueSnackbar("Ocurrió un error al procesar la solicitud. Intenta nuevamente.", { variant: "error" });
                    }
                }
            }
        } catch (error) {
            console.error("Error al actualizar:", error);
            enqueueSnackbar(`Ocurrió un error al actualizar los datos de ${actionType}.`, {
                variant: "error",
            });
        }
    };

    const renderFields = (fields, isOrganization, values, handleChange, setFieldValue, touched, errors) => (
        <Box sx={{ display: "grid", gap: 2, gridTemplateColumns: "1fr 1fr" }}>
            {fields.map((field) => (
                <Box key={field.name} sx={{ gridColumn: field.gridColumn || "span 1" }}>
                    <Typography sx={{ fontSize: "0.775rem", fontWeight: "bold", marginBottom: "4px" }}>
                        {field.label}
                    </Typography>
                    {field.name === "TelefonoUsuario" || field.name === "TelefonoOrganizacion" ? (
                        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                            <Box sx={{ width: "100px", height: "48px", border: "1px solid #ccc", borderRadius: "8px", display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#f0f0f0", gap: "8px" }}>
                                <img src={`${process.env.PUBLIC_URL}/assets/icons/navigation/flag-mx.png`} alt="Bandera México" style={{ width: "28px", height: "20px", borderRadius: "2px" }} />
                                <img src={`${process.env.PUBLIC_URL}/assets/icons/navigation/arrow-drop.svg`} alt="Dropdown" style={{ width: "15px", height: "15px", borderRadius: "2px" }} />
                            </Box>
                            <Field
                                name={field.name}
                                as={TextField}
                                variant="standard"
                                fullWidth
                                InputProps={{ startAdornment: <Typography sx={{ fontSize: "0.875rem", color: "#666", marginRight: "12px" }}>+52</Typography> }}
                                sx={{ "& .MuiInputBase-root": { borderRadius: "8px", border: "1px solid #ccc", padding: "8px 12px", height: "48px", backgroundColor: "#fff" }, "& .MuiInput-underline:before, & .MuiInput-underline:after": { display: "none" } }}
                                onChange={handleChange}
                                error={touched[field.name] && Boolean(errors[field.name])}
                                helperText={touched[field.name] && errors[field.name]}
                            />
                        </Box>
                    ) : field.formType === "select" ? (
                        <ComboBoxFilled
                            data={{ catalog: "giro" }}
                            value={values[field.name] || ""}
                            onChange={(e) => {
                                setFieldValue(field.name, e.target.value);
                                setOrganizationDirty(true);
                            }}
                            sx={{
                                "& .MuiInputBase-root": {
                                    borderRadius: "8px",
                                    border: "1px solid #ccc",
                                    padding: "8px 12px",
                                    height: "40px",
                                    backgroundColor: "#fff",
                                },
                            }}
                        />
                    ) : (
                        <Field
                            name={field.name}
                            as={TextField}
                            variant="standard"
                            fullWidth
                            InputProps={{
                                readOnly: field.name === "NombreOrganizacion" && !isOrganization
                            }}
                            sx={{
                                "& .MuiInputBase-root": {
                                    borderRadius: "8px",
                                    border: "1px solid #ccc",
                                    padding: "8px 12px",
                                    height: "48px",
                                    backgroundColor: field.name === "NombreOrganizacion" && !isOrganization ? "#f0f0f0" : "#fff",
                                    color: field.name === "NombreOrganizacion" && !isOrganization ? "#666" : "#000"
                                },
                                "& .MuiInput-underline:before, & .MuiInput-underline:after": { display: "none" }
                            }}
                            onChange={handleChange}
                            error={touched[field.name] && Boolean(errors[field.name])}
                            helperText={touched[field.name] && errors[field.name]}
                        />
                    )}
                </Box>
            ))}
        </Box>
    );

    const renderPasswordChange = (values, errors, handleChange, touched, handleBlur) => (
        <Box sx={{ display: "grid" }}>
            <Typography
                variant="h3"
                sx={{
                    color: "#000",
                    fontWeight: "bold",
                    marginBottom: "2px",
                }}
            >
                {dataConfig.tituloPsw}
            </Typography>

            <Box mb={5}>
                <Typography
                    onClick={() => setShowPasswordChange(false)}
                    sx={{
                        color: "#1976d2",
                        cursor: "pointer",
                        fontWeight: "bold",
                        display: "flex",
                        alignItems: "center"
                    }}
                >
                    <Box
                        component="img"
                        src={`${process.env.PUBLIC_URL}/assets/icons/navigation/arrow-left.svg`}
                        alt="Arrow Left"
                        sx={{
                            width: "15px",
                            height: "15px",
                            marginRight: "5px",
                            filter: "invert(43%) sepia(69%) saturate(439%) hue-rotate(150deg) brightness(91%) contrast(94%)",
                        }}
                    />
                    Volver
                </Typography>
            </Box>

            <Grid container spacing={2}>
                {dataConfig.passwordFields.map((field) => (
                    <Grid item xs={12} key={field.name}>
                        <Typography sx={{ fontSize: "0.775rem", fontWeight: "bold", marginBottom: "4px" }}>
                            {field.label}
                        </Typography>
                        <TextField
                            fullWidth
                            variant="standard"
                            name={field.name}
                            type="password"
                            value={values[field.name] || ""}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={touched[field.name] && Boolean(errors[field.name])}
                            helperText={touched[field.name] && errors[field.name]}
                            InputLabelProps={{
                                shrink: true,
                                style: {
                                    fontWeight: "bold",
                                    color: "#000",
                                    fontSize: "17px",
                                    marginBottom: "8px",
                                },
                            }}
                            sx={{
                                "& .MuiInputBase-root": {
                                    borderRadius: "8px",
                                    border: "1px solid #ccc",
                                    padding: "8px 12px",
                                    height: "48px",
                                    width: "70%",
                                    backgroundColor: "#fff",
                                },
                                "& .MuiInput-underline:before, & .MuiInput-underline:after": { display: "none" },
                                marginBottom: "10px",
                            }}
                        />
                    </Grid>
                ))}
            </Grid>
        </Box>
    );

    return (
        <Box sx={{ height: "90vh", overflowY: "auto", scrollbarWidth: "none", "&::-webkit-scrollbar": { display: "none" } }}>
            <Box sx={{ maxWidth: "550px", padding: "20px", margin: "0" }}>
                {!showPasswordChange ? (
                    <>
                        <Typography variant="h3" sx={{ fontWeight: "bold", marginBottom: "30px" }}>{dataConfig.titulo}</Typography>
                        <Typography variant="h4" sx={{ fontWeight: "bold", marginBottom: "20px" }}>{dataConfig.subtitulo}</Typography>

                        <Box sx={{ display: "flex", alignItems: "center", marginBottom: "30px" }}>
                            <UploadProfile
                                id={userData.UsuarioID}
                                perfilImagen={PerfilImagenUsuario}
                                setPerfilImagen={""}
                                nombre={NombreUsuario}
                                apellidos={ApellidosUsuario}
                                size={100}
                                inputId="upload-profile-settings"
                                
                            />
                            <Box sx={{ marginLeft: "20px" }}>
                                <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                    <Typography variant="body1" sx={{ color: "#1976d2", fontWeight: "bold", fontSize: "21px" }}>
                                        {`${NombreUsuario || ""} ${ApellidosUsuario || ""}`.trim()}
                                    </Typography>
                                    <Chip
                                        label={getRoleLabel(TipoUsuarioID)}
                                        size="large"
                                        sx={{ backgroundColor: "#e0e0e0", color: "#333", fontSize: "13px", fontWeight: "medium" }}
                                    />
                                </Box>
                                <Typography variant="body2" sx={{ marginTop: "5px", color: "#666" }}>
                                    Miembro desde:{" "}
                                    <span style={{ fontWeight: "bold", color: "#000" }}>
                                        {FechaCreacionUsuario ? new Date(FechaCreacionUsuario).toLocaleDateString("es-ES", { year: "numeric", month: "short", day: "numeric" }) : "No disponible"}
                                    </span>
                                </Typography>
                            </Box>
                        </Box>

                        <Typography variant="h5" sx={{ fontWeight: "bold", marginBottom: "20px" }}>Editar información</Typography>

                        <Formik
                            initialValues={initialUserValues}
                            validationSchema={validateSchemas.usuario}
                            onSubmit={(values, { resetForm }) => handleSubmit(values, "usuario", resetForm)}
                        >
                            {({ values, handleChange, setFieldValue, touched, errors, resetForm, handleSubmit }) => (
                                <Form onChange={() => { setUserDirty(true); }}>
                                    <Box sx={{ marginBottom: "20px" }}>
                                        {renderFields(dataConfig.userFields, false, values, handleChange, setFieldValue, touched, errors)}
                                    </Box>

                                    <Box sx={{ display: "flex", gap: 2, justifyContent: "space-between", alignItems: "center", marginBottom: "60px" }}>
                                        <Button
                                            variant="outlined"
                                            sx={{
                                                color: "#000",
                                                height: "40px",
                                                borderRadius: "8px",
                                                borderColor: "#ccc",
                                                backgroundColor: "#f0f0f0",
                                                textTransform: "none",
                                                fontSize: "13px",
                                                "&:hover": {
                                                    backgroundColor: "#e0e0e0",
                                                    borderColor: "#b3b3b3",
                                                },
                                            }}
                                            onClick={() => {
                                                setShowPasswordChange(!showPasswordChange);
                                            }}
                                        >
                                            Cambiar contraseña
                                        </Button>

                                        <Box sx={{ display: "flex", gap: 2, justifyContent: "flex-end" }}>
                                            {userDirty && (
                                                <Button
                                                    variant="standard"
                                                    onClick={() => {
                                                        resetForm();
                                                        setUserDirty(false);
                                                    }}
                                                    sx={{
                                                        height: "40px",
                                                        color: "#fff",
                                                        fontSize: "13px",
                                                        backgroundColor: "#343D48",
                                                        borderRadius: "8px",
                                                        textTransform: "none",
                                                        "&:hover": { backgroundColor: "#2C3540" }
                                                    }}
                                                >
                                                    Cancelar
                                                </Button>
                                            )}
                                            <Button
                                                variant={userDirty ? "contained" : "outlined"}
                                                disabled={!userDirty}
                                                type="submit"
                                                sx={{
                                                    height: "40px",
                                                    fontSize: "13px",
                                                    borderRadius: "8px",
                                                    textTransform: "none",
                                                    backgroundColor: userDirty ? "#1D74D3" : "transparent",
                                                    color: userDirty ? "#fff" : "#A5C7ED",
                                                    borderColor: userDirty ? "#1D74D3" : "#A5C7ED",
                                                    "&:hover": userDirty ? { backgroundColor: "#1565c0", color: "#fff" } : { backgroundColor: "transparent", borderColor: "#A5C7ED", color: "#A5C7ED" },
                                                }}
                                            >
                                                Guardar cambios
                                            </Button>
                                        </Box>
                                    </Box>
                                </Form>
                            )}
                        </Formik>

                        {TipoUsuarioID !== 3 && (
                            <Formik
                                initialValues={initialOrgValues}
                                validationSchema={validateSchemas.organizacion}
                                onSubmit={(values, { resetForm }) => handleSubmit(values, "organizacion", resetForm)}
                            >
                                {({ values, handleChange, setFieldValue, touched, errors, resetForm, handleSubmit, isSubmitting }) => (
                                    <Form>
                                    <Box sx={{ marginBottom: '40px' }}>
                                        <Typography
                                            variant="h4"
                                            sx={{ fontWeight: 'bold', marginBottom: '20px' }}
                                        >
                                            Organización
                                        </Typography>
                                        <UploadLogoSettings
                                            id={userData.OrganizacionID}
                                            perfilImagen={organizationData.PerfilImagenOrganizacion}
                                            setPerfilImagen={(imagen) =>
                                                setFieldValue('PerfilImagenOrganizacion', imagen)
                                            }
                                        />
                                        {renderFields(
                                            dataConfig.orgFields,
                                            true,
                                            values,
                                            handleChange,
                                            setFieldValue,
                                            touched,
                                            errors
                                        )}
                                    </Box>
                                    <Box sx={{ display: 'flex', gap: 2, justifyContent: 'flex-end' }}>
                                        {organizationDirty && (
                                            <Button
                                                variant="standard"
                                                onClick={() => {
                                                    resetForm();
                                                    setOrganizationDirty(false);
                                                }}
                                                sx={{
                                                    height: '40px',
                                                    color: '#fff',
                                                    fontSize: '13px',
                                                    backgroundColor: '#343D48',
                                                    borderRadius: '8px',
                                                    textTransform: 'none',
                                                    '&:hover': { backgroundColor: '#2C3540' },
                                                }}
                                            >
                                                Cancelar
                                            </Button>
                                        )}
                                        <Button
                                            variant={organizationDirty ? 'contained' : 'outlined'}
                                            disabled={!organizationDirty || isSubmitting}
                                            type="submit"
                                            sx={{
                                                height: '40px',
                                                fontSize: '13px',
                                                borderRadius: '8px',
                                                textTransform: 'none',
                                                backgroundColor: organizationDirty
                                                    ? '#1D74D3'
                                                    : 'transparent',
                                                color: organizationDirty ? '#fff' : '#A5C7ED',
                                                borderColor: organizationDirty ? '#1D74D3' : '#A5C7ED',
                                                '&:hover': organizationDirty
                                                    ? { backgroundColor: '#1565c0', color: '#fff' }
                                                    : {
                                                          backgroundColor: 'transparent',
                                                          borderColor: '#A5C7ED',
                                                          color: '#A5C7ED',
                                                      },
                                            }}
                                        >
                                            Guardar cambios
                                        </Button>
                                    </Box>
                                </Form>
                                )}
                            </Formik>
                        )}
                    </>
                ) : (

                    <Formik
                        initialValues={{ newPassword: "", confirmPassword: "" }}
                        validationSchema={validateSchemas.cambioPassword}
                        onSubmit={(values) => handleSubmit(values, "password")}
                    >
                        {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
                            <>
                                <form onSubmit={handleSubmit}>
                                    {renderPasswordChange(values, errors, handleChange, touched, handleBlur)}

                                    <Box display="flex" justifyContent="flex-end" mt={2} mr={19}>
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            sx={{
                                                height: "40px",
                                                width: "160px",
                                                fontSize: "13px",
                                                backgroundColor: "#1D74D3",
                                                color: "#fff",
                                                textTransform: "none",
                                                borderRadius: "8px",
                                                "&:hover": {
                                                    backgroundColor: "#156a9e",
                                                },
                                            }}
                                        >
                                            Guardar Contraseña
                                        </Button>
                                    </Box>
                                </form>
                            </>
                        )}
                    </Formik>
                )}
            </Box>
            <Modal
                open={showModal}
                onClose={() => setShowModal(false)}
                aria-labelledby="password-update-modal-title"
                aria-describedby="password-update-modal-description"
            >
                <Box

                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: 420,
                        bgcolor: "background.paper",
                        boxShadow: 24,
                        p: 4,
                        borderRadius: 2,
                        textAlign: "center",
                    }}
                >
                    <img
                        src={`${process.env.PUBLIC_URL}/assets/icons/navigation/alert-ic-check.svg`}
                        alt="Check Icon"
                        style={{
                            height: "40px",
                            marginBottom: "20px",
                        }}
                    />
                    <Typography
                        id="password-update-modal-title"
                        variant="h5"
                        sx={{
                            fontWeight: "bold",
                            fontSize: "20px",
                            marginBottom: 2,
                            paddingX: 0,
                            width: "100%",
                        }}
                    >
                        Contraseña actualizada
                    </Typography>
                    <Typography
                        id="password-update-modal-description"
                        sx={{
                            marginBottom: 4,
                            fontSize: "14px",
                            paddingX: 3,
                        }}
                    >
                        ¡Listo! Tu contraseña fue actualizada correctamente.
                    </Typography>

                    <Box display="flex" justifyContent="center" gap="20px">
                        <Button
                            variant="contained"
                            onClick={() => {
                                setShowModal(false);
                                setShowPasswordChange(false);
                            }}
                            sx={{
                                backgroundColor: "#1D74D3",
                                width: "180px",
                                color: "#fff",
                                textTransform: "none",
                                borderRadius: "8px",
                                fontSize: "13px",
                                padding: "10px 25px",
                                "&:hover": { backgroundColor: "#185fac" },
                            }}
                        >
                            Aceptar
                        </Button>
                    </Box>
                </Box>
            </Modal>

        </Box>
    );
};

