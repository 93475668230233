import { usersConfig } from "../../data/formsConfig/usersConfig";
import { organizationsConfig } from "../../data/formsConfig/organizationsConfig";
import { categoriesConfig } from "../../data/formsConfig/categoriesConfig";

export const formConfig = async (formType, data = null) => {
    const baseConfigs = {
        users: usersConfig(data),
        organizations: organizationsConfig(data),
        categories: categoriesConfig(data),
    };

    const overrides = {
        //-------Usuarios-------
        initialUser: {
            dataConfig: {
                ...baseConfigs.users.dataConfig, 
                title: "Usuarios",
                validateSchemas: baseConfigs.users.validateSchemas,
            },
        },
        addUser: {
            successMessage: "Usuario creado exitosamente",
            errorMessage: "Error al crear usuario",
            dataConfig: {
                ...baseConfigs.users.dataConfig, 
                title: "Agregar Usuario", 
                validateSchemas: baseConfigs.users.validateSchemas,
                
            },
            endpoint: `nuevo/usuario`,
        },
        editUser: {
            successMessage: "Usuario actualizado exitosamente",
            errorMessage: "Error al editar usuario",
            dataConfig: {
                ...baseConfigs.users.dataConfig,
                title: "Editar Información", 
                validateSchemas: baseConfigs.users.validateSchemas,
                
            },
            endpoint: "editar/usuario",
        },
        deleteUser: {
            title: `¿De verdad quieres eliminar a ${data?.nombre || "este usuario"} ${data?.apellidos}?`,
            description: "El usuario ya no tendrá acceso a esta plataforma. Esta acción no se puede deshacer.",
            successMessage: "Usuario eliminado exitosamente",
            errorMessage: "Error al eliminar usuario",
            endpoint: `/eliminar/usuario`,
            
        },

        //-------Organizaciones-------
        initialOrganization: {
            dataConfig: {
                ...baseConfigs.organizations.dataConfig, 
                title: "Organizaciones",
                validateSchemas: baseConfigs.organizations.validateSchemas, 
            },
        },
        addOrganization: {
            successMessage: "Organización creada exitosamente",
            errorMessage: "Error al crear organización",
            dataConfig: {
                ...baseConfigs.organizations.dataConfig, 
                title: "Agregar Organización", 
                validateSchemas: baseConfigs.organizations.validateSchemas, 
                
            },
            endpoint: `nuevo/organizacion`,
        },
        editOrganization: {
            successMessage: "Organización actualizada exitosamente",
            errorMessage: "Error al editar organización",
            dataConfig: {
                ...baseConfigs.organizations.dataConfig,
                title: "Editar Organización", 
                validateSchemas: baseConfigs.organizations.validateSchemas, 
                
            },
            endpoint: "editar/organizacion",
        },
        deleteOrganization: {
            title: `¿De verdad deseas eliminar a ${data?.nombre}?`,
            description: "Esta acción no se puede deshacer",
            successMessage: "Organización eliminada exitosamente",
            errorMessage: "Error al eliminar organización",
            endpoint: `eliminar/organizacion`,
        },

        //-------Categorias-------
        initialCategory: {
            dataConfig: {
                ...baseConfigs.categories.dataConfig, 
                title: "Categorias", 
            },
        },
        addCategory: {
            successMessage: "Categoria creada exitosamente",
            errorMessage: "Error al crear categoria",
            dataConfig: {
                ...baseConfigs.categories.dataConfig, 
                title: "Agregar categoria", 
                validateSchemas: baseConfigs.categories.validateSchemas, 
                
            },
            endpoint: `nuevo/categoria`,
        },
        editCategory: {
            successMessage: "Categoria actualizada exitosamente",
            errorMessage: "Error al editar categoria",
            dataConfig: {
                ...baseConfigs.categories.dataConfig,
                title: "Editar categoria", 
                validateSchemas: baseConfigs.categories.validateSchemas, 
                
            },
            endpoint: "editar/categoria",
        },
        deleteCategory: {
            title: `¿De verdad deseas eliminar ${data?.nombre}?`,
            description: "Al borrar esta categoría, no podrás usarla en futuras operaciones. Está accián no se puede deshacer ",
            successMessage: "Categoria eliminada exitosamente",
            errorMessage: "Error al eliminar categoria",
            endpoint: `eliminar/categoria`,
        },

    };

    const finalConfig = {
        ...baseConfigs[formType.split("Organization")[0]],
        ...overrides[formType],
    };

    return finalConfig;
};
