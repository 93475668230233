import * as yup from "yup";
import { usersFields } from "../forms/users";
import { tableHeaders } from "../tableHeaders/users";

export const usersConfig = (userData = null) => {
    const initValues = {
        nombre: userData?.nombre || "",
        apellidos: userData?.apellidos || "",
        correo: userData?.correo || "",
        telefono: userData?.telefono || "",
        organizacionID: userData?.organizacionID || null,
        tipoUsuID: userData?.tipoUsuID || null,
        fechaCreacion: userData?.fechaCreacion || null,
        perfilImagen: userData?.perfilImagen || null,
    };

    const dataConfig = {
        title:"Usuarios",
        module: "users",
        actionModule: "User",
        catalogo: "usuarios",
        filtro:"usuarios",
        filterKey: "tipoUsuIDs",
        filterText: "Tipo de usuario",
        placeholder: "Buscar cliente, correo eletrónico y telefono",
        placeholderCB: "Seleccionar..",
        gridTemplateColumns: "1fr 1fr",
        getData: "listado/usuarios",
        tableHeader: tableHeaders,
        fields: usersFields, 
    };
    
    const validateSchemas = yup.object().shape({
        nombre: yup
            .string()
            .matches(/^[a-zA-ZáéíóúÁÉÍÓÚñÑ\s]+$/, "El nombre debe de ser Alfanumérico")
            .required("El nombre es requerido"),
        apellidos: yup
            .string()
            .matches(/^[a-zA-ZáéíóúÁÉÍÓÚñÑ\s]+$/, "Los apellidos deben de ser Alfanumérico")
            .required("Los apellidos son requeridos"),
        correo: yup
            .string()
            .email("Correo inválido")
            .required("El correo es requerido"),
        telefono: yup
            .string()
            .notRequired()
            .test(
                "es-numero-valido",
                "El teléfono debe contener exactamente 10 dígitos y solo números",
                (value) => {
                    if (!value) return true;
                    return /^\d{10}$/.test(value);
                }
            ),
        organizacionID: yup
            .number()
            .required("La organización es requerida")
            .notOneOf([0], "La organización es requerida"),
        
        tipoUsuID: yup
            .number()
            .required("El tipo de usuario es requerido")
            .notOneOf([0], "El tipo de usuario es requerido"),
    });    

    return { initValues, validateSchemas, dataConfig };
};
