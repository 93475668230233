// organizations.js
export const organizationsFields = [
    {
        name: "nombre",
        label: "Nombre de la organización",
        gridColumn: "span 2", 
        formType: "text",
    },
    {
        name: "representante",
        label: "Representante",
        gridColumn: "span 2", 
        formType: "text",
    },
    {
        name: "giro",
        label: "Giro",
        gridColumn: "span 1",
        formType: "select",
        catalog: "giro",
    },
    {
        name: "correo",
        label: "Correo electrónico",
        gridColumn: "span 1",
        formType: "text",
    },
    {
        name: "telefono",
        label: "",
        gridColumn: "span 1",
        formType: "text",
    },
    {
        name: "factorUtilidad",
        label: "Factor de utilidad",
        gridColumn: "span 1",
        formType: "text", 
    },
];
